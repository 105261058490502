import React from 'react';
import { Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

// DashBoard
import Dashboard from '../Pages/Dashboard/Index';

// Calendar
import Calendar from '../Pages/Calendar/index';

// Chat
import Chat from '../Pages/Chat/Chat';

// Email
import Inbox from '../Pages/Email/Inbox';
import ReadEmail from '../Pages/Email/ReadEmail';

// Ecommerce
import AddProduct from '../Pages/Ecommerce/AddProduct';
import Cart from '../Pages/Ecommerce/Cart';
import Checkout from '../Pages/Ecommerce/Checkout';
import Customers from '../Pages/Ecommerce/EcommerceCustomer/Customers';
import Orders from '../Pages/Ecommerce/EcommerceOrder/Orders';
import ProductDetail from '../Pages/Ecommerce/ProductDetail';
import Product from '../Pages/Ecommerce/Products';
import Shops from '../Pages/Ecommerce/Shops';

// Invoices
import InvoicesDetail from '../Pages/Invoices/InvoiceDetails';
import InvoicesList from '../Pages/Invoices/InvoicesList';

// Contact
import UserGrid from '../Pages/Contacts/UserGrid';
import UserList from '../Pages/Contacts/UserList';
import ContactUserProfile from '../Pages/Contacts/UserProfile';

// UiElememnt
import UiAlerts from '../Pages/UIElements/UiAlerts';
import UiButtons from '../Pages/UIElements/UiButtons';
import UiCards from '../Pages/UIElements/UiCards';
import UiCarousel from '../Pages/UIElements/UiCarousel';
import UiDropdowns from '../Pages/UIElements/UiDropdowns';
import UiGrid from '../Pages/UIElements/UiGrid';
import UiImages from '../Pages/UIElements/UiImages';
import UiModals from '../Pages/UIElements/UiModals';
import UiOffcanvas from '../Pages/UIElements/UiOffcanvas';
import UiPlaceholders from '../Pages/UIElements/UiPlaceholders';
import UiProgressBars from '../Pages/UIElements/UiProgressBars';
import UiTabs from '../Pages/UIElements/UiTabs&Accordions';
import UiTypography from '../Pages/UIElements/UiTypography';
import UiVideo from '../Pages/UIElements/UiVideo';
import UiGeneral from '../Pages/UIElements/UiGeneral';
import UiColors from '../Pages/UIElements/UiColors';

// Extended Element
import ExLightbox from '../Pages/ExtendedElement/ExLightbox';
import ExRangSlider from '../Pages/ExtendedElement/ExRangeSlider';
import ExRating from '../Pages/ExtendedElement/ExRating';
import ExNotification from '../Pages/ExtendedElement/ExNotification';

// Forms
import BasicElements from '../Pages/Forms/BasicElements';
import Validation from '../Pages/Forms/Validation';
import AdvancedPlugins from '../Pages/Forms/AdvancedPlugins';
import Editors from '../Pages/Forms/Editors';
import FileUpload from '../Pages/Forms/FileUpload';
import Wizard from '../Pages/Forms/Wizard';
import Mask from '../Pages/Forms/Mask';

// Tables
import BootstrapBasic from '../Pages/Tables/BootstrapBasic';
import AdvanceTable from '../Pages/Tables/AdvanceTable';

// Charts
import ApexCharts from '../Pages/Charts/ApexCharts';
import ChartjsCharts from '../Pages/Charts/Chartjs';

// Icons
import FeatherIcons from '../Pages/Icons/Feathericon';
import BoxIcons from '../Pages/Icons/Boxicons';
import MaterialDesignIcons from '../Pages/Icons/MaterialDesign';
import DripIcons from '../Pages/Icons/Dripicons';
import FontAwesomeIcons from '../Pages/Icons/FontAwesome';

// Maps
import GoogleMaps from '../Pages/Maps/GoogleMaps';
import VectorMaps from '../Pages/Maps/VectorMaps';
import LeafletMaps from '../Pages/Maps/LeafletMaps';

// Inner Authentication Pages
import InnerLogin from "../Pages/InnerAuthPages/login";
import InnerRegister from "../Pages/InnerAuthPages/Register";
import RecoverPassword from "../Pages/InnerAuthPages/RecoverPassword";
import LockScreen from "../Pages/InnerAuthPages/LockScreen";
import InnerLogout from "../Pages/InnerAuthPages/Logout";
import ConfirmMail from "../Pages/InnerAuthPages/ConfirmMail";
import EmailVerification from "../Pages/InnerAuthPages/EmailVerification";
import TwoStepVerification from "../Pages/InnerAuthPages/TwoStepVerification";

// Utility Pages
import StarterPage from '../Pages/Utility/pages-starter';
import MaintenancePage from '../Pages/Utility/pages-maintenance';
import ComingSoonPage from '../Pages/Utility/pages-comingsoon';
import TimelinePage from '../Pages/Utility/pages-timeline';
import FaqsPage from '../Pages/Utility/pages-faqs';
import PricingPage from '../Pages/Utility/pages-pricing';
import Error404 from '../Pages/Utility/pages-404';
import Error500 from '../Pages/Utility/pages-500';

// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import Register from "../Pages/Authentication/Register";
import UserProfile from "../Pages/Authentication/user-profile";


//realestate
import Location from '../Pages/Real_Estate/Location';
import Building from '../Pages/Real_Estate/Building';
import SupLocation from '../Pages/Real_Estate/SupLocation';
import Apartment from '../Pages/Real_Estate/Apartment';



//User
import Employees from '../Pages/User/Employees';
import Customer from '../Pages/User/Customer';
import Assign from '../Pages/User/assign/Assign';


import ClientProfile from '../Pages/User/clientProfile/ClientProfile';
import BuildingDetials from '../Pages/Real_Estate/Buildingdetails/BuildingDetials';
import ClientProfile2 from '../Pages/User/clientProfile/ClientProfile2';
import Review from '../Pages/User/clientProfile/Review';
import SellesAssign from '../components/SellesAssign/SellesAssign';
import AdminMettings from '../components/AdminMetings/AdminMettings';
import AssignedCustomer from '../Pages/User/AssignedCustomer/AssignedCustomer';
import AssignedNotCustomer from '../Pages/User/AssignedNotCustomer/AssignedNotCustomer';
import Assigncusleads from '../Pages/User/AssignedCustomer/Assigncusleads/Assigncusleads';
import Assigncuscold from '../Pages/User/AssignedCustomer/Assigncuscold/Assigncuscold';
import NotAssignedLeads from '../Pages/User/NotAssignedCustomers/NotassignedLeads/NotAssignedLeads';
import NotAssignedcold from '../Pages/User/NotAssignedCustomers/NotAssinedcold/NotAssignedcold';
import Assnotassleads from '../Pages/User/assign/Assignnotassign/Assnotassleads/Assnotassleads';
import Assnotasscold from '../Pages/User/assign/Assignnotassign/Assnotasscold/Assnotasscold';
import Assasscold from '../Pages/User/assign/AssignAssign/assasscold/Assasscold';
import AssAssLeads from '../Pages/User/assign/AssignAssign/assassleads/AssAssLeads';
import Range2 from '../Pages/User/clientProfile/range2/Range2';
import ClientCalls from '../Pages/User/clientProfile/ClientCalls/ClientCalls';
import AdminRequests from '../Pages/User/AdminReqs/AdminRequests';
import SalesBuilding from '../Pages/Real_Estate/SalesBuildings';
import UserCallings from '../Pages/UserCallings/UserCallings';
import FollowUp from '../Pages/FollowUp/FollowUp';
import TealSells from '../Pages/TealSells/TealSells';
import DoneDeal from '../Pages/DoneDeal/DoneDeal';
import Meetings from '../Pages/meetings/Meetings';
import Developers from '../Pages/developers/Devlopers';
import DevProjects from '../Pages/DevProjects/DevProjects';
import Requests from '../Pages/Requests/Requests';
import Selles from '../Pages/Selles/Selles';
import SellesFollowUp from '../Pages/SellesFollowUp/SellesFollowUp';
import TodayMeeting from '../Pages/TodayMeeting/TodayMeeting';
import Unites from '../Pages/Unites/Unites';
import NotAnswer from '../Pages/NotAnswer/NotAnswer';
import OtherCold from '../Pages/OtherCold/OtherCold';
import PendingReq from '../Pages/PendingReqs/PendingReqs';
import FileClients from '../Pages/FileClients/FileClients';
import AllFollowsUp from '../Pages/AllFollowsUp/AllFollowsUp';
import SallesAssignedClis from '../Pages/SallesAssignedClis/SallesAssignedClis';
import Archived from '../Pages/Archived/Archived';
import Test from '../Pages/Test/Test';
import NewClientPage from '../Pages/NewClientPage/NewClientPage';
import Leads from '../Pages/Leads/Leads';
import Colds from '../Pages/Colds/Colds';
import Sheets from '../Pages/Sheets/Sheets';
import NotInterested from '../Pages/NotInterested/NotInterested';
import LowBudget from '../Pages/LowBudget/LowBudget';
import LeadsNotAnswer from '../Pages/LeadsNotAnswer/LeadsNotAnswer';
import ColdNotAnswer from '../Pages/ColdNotAnswer/ColdNotAnswer';
import CallBack from '../Pages/CallBack/CallBack';
import Delay from '../Pages/Delay/Delay';
import ColdSheets from '../Pages/ColdSheets/ColdSheets';
import OtherLeads from '../Pages/OtherLeads/OtherLeads';
import PendingCleints from '../Pages/PendingCleints/PendingCleints';
import SalesLeads from '../Pages/SalesLeads/SalesLeads';
import SalesColds from '../Pages/SalesColds/SalesColds';
import Teams from '../Pages/Teams/Teams';
import UserProfileTwo from '../Pages/OtherLeads/Profile';
import PendOpMar from '../Pages/PendOpMar/PendOpMar';
import UnAssPerOper from '../Pages/UnAssPerOper/UnAssPerOper';
import UnAssNotAnswer from '../Pages/UnAssNotAnswer/UnAssNotAnswer';
import UnAssCallBack from '../Pages/UnAssCallBack/UnAssCallBack';
import UnAssLowBudject from '../Pages/UnAssLowBudject/UnAssLowBudject';
import UnAssNotInterested from '../Pages/UnAssNotInterested/UnAssNotInterested';
import UnAssInterested from '../Pages/UnAssInterested/UnAssInterested';
import SalesDashboard from '../Pages/SalesDashboard/SalesDashboard';
import Interesed from '../Pages/Interested/Interested';
import NewMeetings from '../Pages/NewMeetings/NewMeetings';
import FollowMeetings from '../Pages/FollowMeetings/FollowMeetings';
import DoneDeals from '../Pages/Deals/DoneDeals';
import LostDeals from '../Pages/Deals/LostDeals';
import TestTable from '../Pages/TestTable';
import UnAssFollorwMeeting from '../Pages/UnAssFollorwMeeting/UnAssFollorwMeeting';
import { AgTable } from '../Pages/AgTable/AgTable';
import UnAssDoneDeal from '../Pages/UnAssDoneDeal/UnAssDoneDeal';
import UnAssLostDeal from '../Pages/UnAssLostDeal/UnAssLostDeal';
import Search from '../Pages/search/Search';

const authProtectedRoutes = [

  // dashboard
  { path: "/dashboard", component: <Dashboard /> },

  // Profile
  { path: "/userprofile", component: <UserProfile /> },

  // Calendar
  { path: "/apps-calendar", component: <Calendar /> },


  // Chat
  { path: "/apps-chat", component: <Chat /> },


  // Email
  { path: "/email-inbox", component: <Inbox /> },
  { path: "/email-read", component: <ReadEmail /> },


  // Ecommerce
  { path: "/ecommerce-product-detail/:id", component: <ProductDetail /> },
  { path: "/ecommerce-products", component: <Product /> },
  { path: "/ecommerce-orders", component: <Orders /> },
  { path: "/ecommerce-customers", component: <Customers /> },

  { path: "/assign", component: <Assign /> },

  { path: "/assignnotassleads", component: <Assnotassleads /> },
  { path: "/assignnotasscold", component: <Assnotasscold /> },

  { path: "/assignasscold", component: <Assasscold /> },
  { path: "/assignassleads", component: <AssAssLeads /> },
  { path: "/pending_clients", component: <PendingCleints /> },
  { path: "/sales_leads", component: <SalesLeads /> },
  { path: "/sales_colds", component: <SalesColds /> },
  { path: "/teams", component: <Teams /> },





  { path: "/ecommerce-cart", component: <Cart /> },
  { path: "/ecommerce-checkout", component: <Checkout /> },
  { path: "/ecommerce-shops", component: <Shops /> },
  { path: "/ecommerce-add-product", component: <AddProduct /> },


  // Invoices
  { path: "/invoices-detail", component: <InvoicesDetail /> },
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/UserProfile/:id", component: <UserProfileTwo /> },

  // Contact
  { path: "/contacts-grid", component: <UserGrid /> },
  { path: "/contacts-list", component: <UserList /> },
  { path: "/contacts-profile", component: <ContactUserProfile /> },


  // Utility Pages
  { path: "/pages-starter", component: <StarterPage /> },
  { path: "/pages-timeline", component: <TimelinePage /> },
  { path: "/pages-faqs", component: <FaqsPage /> },
  { path: "/pages-pricing", component: <PricingPage /> },


  // UiElement
  { path: "/ui-alerts", component: <UiAlerts /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdowns /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-modals", component: <UiModals /> },
  { path: "/ui-offcanvas", component: <UiOffcanvas /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-progressbars", component: <UiProgressBars /> },
  { path: "/ui-tabs-accordions", component: <UiTabs /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-colors", component: <UiColors /> },


  // Extended Element
  { path: "/extended-lightbox", component: <ExLightbox /> },
  { path: "/extended-rangeslider", component: <ExRangSlider /> },
  { path: "/extended-rating", component: <ExRating /> },
  { path: "/extended-notifications", component: <ExNotification /> },


  // Real-Estate Element
  { path: "/Location", component: <Location/> },
  { path: "/Category", component: <Apartment /> },
  { path: "/Building", component: <Building /> },
  { path: "/SupLocation", component: <SupLocation /> },


  // User Element
  { path: "/Customer", component: <Customer/> },

  { path: "/customerassigned", component: <AssignedCustomer/> },
  { path: "/customernotassigned", component: <AssignedNotCustomer/> },

  { path: "/asscusleads", component: <Assigncusleads/> },
  { path: "/asscuscold", component: <Assigncuscold/> },

  { path: "/notasscusleads", component: <NotAssignedLeads/> },
  { path: "/notasscuscold", component: <NotAssignedcold/> },

  { path: "/Employees", component: <Employees /> },



  // Forms
  { path: "/form-elements", component: <BasicElements /> },
  { path: "/form-validation", component: <Validation /> },
  { path: "/form-advanced", component: <AdvancedPlugins /> },
  { path: "/form-editor", component: <Editors /> },
  { path: "/form-uploads", component: <FileUpload /> },
  { path: "/form-wizard", component: <Wizard /> },
  { path: "/form-mask", component: <Mask /> },


  // Tables
  { path: "/tables-basic", component: <BootstrapBasic /> },
  { path: "/table-advanced", component: <AdvanceTable /> },

  // Charts
  { path: "/charts-apex", component: <ApexCharts /> },
  { path: "/charts-chartjs", component: <ChartjsCharts /> },


  // Icons
  { path: "/icons-feather", component: <FeatherIcons /> },
  { path: "/icons-boxicons", component: <BoxIcons /> },
  { path: "/icons-materialdesign", component: <MaterialDesignIcons /> },
  { path: "/icons-dripicons", component: <DripIcons /> },
  { path: "/icons-fontawesome", component: <FontAwesomeIcons /> },


  // Maps
  { path: "/maps-google", component: <GoogleMaps /> },
  { path: "/maps-vector", component: <VectorMaps /> },
  { path: "/maps-leaflet", component: <LeafletMaps /> },
  {path:"/clientprofile",component:<ClientProfile/>},
  {path:"/buildingdetails",component:<BuildingDetials/>},
  {path:"/colds",component:<Colds/>},
  {path:"/sheets",component:<Sheets/>},
  {path:"/cold_sheets",component:<ColdSheets/>},
  {path:"/other_leads",component:<OtherLeads/>},
  {path:'/clientprofile2',component:<ClientProfile2/>},
  {path:'/other_colds',component:<OtherCold/>},
  {path:"/review",component:<Review/>},
  {path:"/user_callings",component:<UserCallings/>},
  {path:"/AllFollowsUp",component:<AllFollowsUp/>},
  {path:"/clientcalls",component:<ClientCalls/>},
  {path:'/sellass',component:<SellesAssign/>},
  {path:'/leads',component:<Leads/>},
  {path:'/PendOpMar',component:<PendOpMar/>},
  {path:'/TestTable',component:<TestTable/>},
  {path:'/UnAssFollorwMeeting',component:<UnAssFollorwMeeting/>},
  {path:'/AgTable',component:<AgTable/>},
  {path:'/UnAssDoneDeal',component:<UnAssDoneDeal/>},
  {path:'/UnAssLostDeal',component:<UnAssLostDeal/>},
  {path:'/UnAssPerOper',component:<UnAssPerOper/>},
  {path:'/UnAssNotAnswer',component:<UnAssNotAnswer/>},
  {path:'/UnAssCallBack',component:<UnAssCallBack/>},
  {path:'/UnAssNotInterested',component:<UnAssNotInterested/>},
  {path:'/UnAssInterested',component:<UnAssInterested/>},
  {path:'/NewMeetings',component:<NewMeetings/>},
  {path:'/FollowMeetings',component:<FollowMeetings/>},
  {path:'/DoneDeals',component:<DoneDeals/>},
  {path:'/LostDeals',component:<LostDeals/>},
  {path:'/SalesDashboard',component:<SalesDashboard/>},
  {path:'/search',component:<Search/>},
  {path:'/Interested',component:<Interesed/>},
  {path:'/UnAssLowBudject',component:<UnAssLowBudject/>},
  {path:'/new_client_page',component:<NewClientPage/>},
  {path:'/Test',component:<Test/>},
  {path:'/Archived',component:<Archived/>},
  {path:'/SallesAssignedClis',component:<SallesAssignedClis/>},
  {path:'/not_answer',component:<NotAnswer/>},
  {path:'/pending',component:<PendingReq/>},
  {path:'/file_clients',component:<FileClients/>},
  {path:'/not_interested',component:<NotInterested/>},
  {path:'/low_budget',component:<LowBudget/>},
  {path:'/leads_not_answer',component:<LeadsNotAnswer/>},
  {path:'/cold_not_answer',component:<ColdNotAnswer/>},
  {path:'/call_back',component:<CallBack/>},
  {path:'/delay',component:<Delay/>},
  {path:'/meetings',component:<Meetings/>},
  {path:'/SellesFollowUp',component:<SellesFollowUp/>},
  {path:'/developers',component:<Developers/>},
  {path:'/dev_projects',component:<DevProjects/>},
  {path:'/requests',component:<Requests/>},
  {path:'/selles',component:<Selles/>},
  {path:'/TodayMeeting',component:<TodayMeeting/>},
  {path:'/Unites',component:<Unites/>},
  {path:'/follow_up',component:<FollowUp/>},
  {path:'/teal_seals',component:<TealSells/>},
  {path:'/done_deal',component:<DoneDeal/>},
  {path:'/sales_building',component:<SalesBuilding/>},
  {path:'/adminmetting',component:<AdminMettings/>},
  {path:'/adminreqs',component:<AdminRequests/>},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  // { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  // Inner Auth Pages
  { path: "/login", component: <InnerLogin /> },
  { path: "/auth-register", component: <InnerRegister /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-logout", component: <InnerLogout /> },
  { path: "/auth-confirm-mail", component: <ConfirmMail /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-two-step-verification", component: <TwoStepVerification /> },

  // Utility Pages
  { path: "/pages-maintenance", component: <MaintenancePage /> },
  { path: "/pages-comingsoon", component: <ComingSoonPage /> },
  { path: "/pages-404", component: <Error404 /> },
  { path: "/pages-500", component: <Error500 /> },
];

export { authProtectedRoutes, publicRoutes };
