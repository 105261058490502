import { Button, Card, Col, Input, Modal, Select, Space, Spin, Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlinePlus, AiOutlinePlusCircle, AiOutlineSearch } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
import { AppContext } from '../../components/context/AppContextProvider';
import moment from 'moment';
import 'react-tabulator/lib/styles.css';
import 'tabulator-tables/dist/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'
import 'react-tabulator/lib/styles.css';
import * as XLSX from 'xlsx';

import "tabulator-tables/dist/css/tabulator.min.css";
import Lottie from 'lottie-react';
import Highlighter from 'react-highlight-words';
import { AgGridReact } from 'ag-grid-react';
const Interested = () => {
  const [page,setPage]=useState();
  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) ,
        Number(dateParts[0]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2024,
    maxValidYear: 3000,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const gridRef = useRef();

  const authUser=localStorage.getItem("authUser");
  let uData=JSON.parse(authUser);
  const [selectedRows,setSelectedRows]=useState([]);

  const [showAddMeeting,setShowAddMeeting]=useState(false)
  const [showCalls,setShowCalls]=useState(false);
  const [clockTime,setClockTime]=useState('');
  const[selectAll,setSelectAll]=useState(false)
  const [columns,setColumns]=useState([]);
  const [filteredRowCount,setFilteredRowCount]=useState([])
  const gridStyle = useMemo(() => ({ height: "100vh" }), []);


  const [meetingLoading,setMeetingLoading]=useState(false)
  const [selectedemployee, setselectedemployee] = useState("");
  const [employeessdata, setemployeessdata] = useState([]);
  const [assignloading, setassignloading] = useState(false);
  const [calls,setCalls]=useState([])
  const [meeting,setMeeting]=useState('')
  const [disConf,setDisConf]=useState(true)

  const [showModal,setShowModal]=useState(false)
  const [unAssLoading,setUnAssLoading]=useState(false)
  const [newCall,setNewCall]=useState({
    feed_back:'',
    duration:'',
  })
  const [showAddModal,setShowAddModal]=useState(false)

  const [callLoading,setCallLoading]=useState(false);
  const [addLoading,setAddLoading]=useState(false)
  const [showHistoyModel,setShowHistoyModel]=useState(false);
  const [historyLoading,setHistoryLoading]=useState(false)
  const [ClientHistory,setClientHistory]=useState([])
  const [callBackData,setCallBackData]=useState({
    time:'',
    date:'',
  })
  // console.log(uData,"uData")
  const {user}=useContext(AppContext);
  const onFilterChanged = (params) => {
    const rowCount = params.api.getDisplayedRowCount();
    setFilteredRowCount(rowCount);
  };

  const handleExport = () => {
    const pp = [];
    data.map((item, index) => {
      if (item.check === true) {
        console.log('dsd');
        let newobj = {
          'Cleint name': item.name || '',
          'Cleint phone': item.phone || '',
          'Cleint phone2': item.phone2 || '',
          'Cleint project_intrested_in': item.project_intrested_in || '',
          'Cleint time_to_call': item.time_to_call || '',
          'Cleint unitcode': item.unitcode || '',
          'Cleint client_type': item.client_type || '',
          'Cleint status': item.status || '',
          'Cleint status label': item.status_label || '',
          'Cleint job': item.job || '',
          'Cleint address': item.address || '',
          'agent name': item.agent_name || '',
          'agent Email': item.agent_email || '',
          'agent Phone': item.agent_phone || '',
        };
        pp.push(newobj);
      }
    });
    if (pp.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    // XLSX.writeFile(wb, 'MyExcel.XLSX');
  };

const assignfunction = () => {
  let selected = [...data];
  let checddata = selected.filter((item) => item.check == true);
  // console.log(checddata)
  // return
  let selectedtxt = "";
  if (selectedemployee == "") {
    toast.warn("select at least one employee");
  }
  for (let i = 0; i < checddata.length; i++) {
    if (i == 0) {
      selectedtxt += checddata[i].client_id;
    } else {
      selectedtxt += "**UAD**" + checddata[i].client_id;
    }
  }
  const data_send = {
    client_id_data: selectedtxt,
    agent_id: selectedemployee,
  };
  console.log(data_send);
  setassignloading(true);
  axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/assign_agent_to_client.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res);
      if (res.status === "success") {
        let alldata = [...data];
        alldata.map((item) => {
          return { ...item, check: false };
        });
        //retnotchecked();
        setData(alldata);

        toast.success(res.message);
        getNotAnswer();
      } else if (res.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("something is error try again");
      }
    })
    .finally(() => {
      setassignloading(false);
    });
};
  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    // pagination:true, //enable pagination
    // paginationSize:10, //optional parameter to request a certain number of rows per page
    // paginationInitialPage:1,
    // paginationSizeSelector:[5,10,20, 25,30,40, 50],
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  // console.log(user)
  let agentData=JSON.parse(localStorage.getItem("authUser"));
  const [pageloading,setpageloading]=useState(true);
  const [loading,setLoading]=useState(true);
  // const [addLoading,setAddLoading]=useState(false)
  const [data,setData]=useState([]);
  const [showNewStatusModal,setShowNewStatusModal]=useState(false)
  const [rowData,setRowData]=useState({})
  const [newStatus,setNewStatus]=useState('interested')
  const [feedBack,setFeedBack]=useState('');
  const [changeLoading,setChangeLoading]=useState(false)
  const [statusOptions,setStatusOptions]=useState([
    {
      value:"interested",
      label:"interested",
    },
    {
      value:"not_interested",
      label:"not interested",
    },
    {
      value:"low_budget",
      label:"low budget",
    },
    {
      value:"call_back",
      label:"call back",
    },
    {
      value:"follow_meeting",
      label:"Follow Meeting",
    },
    {
      value:"done_deal",
      label:"Done Deal",
    },
    {
      label:"Lost Deal",
      value:"lost_deal",
    },
    // {
    //   value:"delay",
    //   label:"Delay",
    // },
  ])
  const agent_id=JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [originalData,setOriginalData]=useState([]);
  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log('Content copied to clipboard');
      toast.success('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  const nav =useNavigate()


  const handleGetCalls=async(id)=>{
    setCallLoading(true)
    const data_send={
      client_id:id
    }
    await axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/new_client_calls.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res.message)
      if(res.status=='success'){
        if(Array.isArray(res.message)){
          setCalls(res.message);
        }
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setCallLoading(false)
    })
  }
  const callField= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');
    const btn=document.createElement('button');
    btn.innerText="Calls";
    btn.style.color="white";
    btn.style.backgroundColor="#0b5ed7"
    btn.style.border='none'
    btn.style.padding='10px'
    btn.style.borderRadius='4px'
    btn.addEventListener('click',function(){
      console.log(rowData);
      handleGetCalls(rowData?.client_id)
      setRowData(rowData?.client_id)
      setShowCalls(true)
    })
    div.appendChild(btn)

    return div
  }

  const phone1lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    if(rowData.phone!=null){
      imgs_div.appendChild(imgcopy);
      imgs_div.appendChild(img);

    }
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      // console.log(rowData)
      copyContent(rowData.phone)
    })
    return div;
  }

  const phone2lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.phone2;
    if(rowData?.phone2!=undefined){
      div.appendChild(phone);
    }
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.phone2.startsWith("0")?rowData.phone2:`0${rowData.phone2}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
    });

    const imgs_div=document.createElement('div');
    if(rowData.phone2!=null){
      imgs_div.appendChild(imgcopy);
      imgs_div.appendChild(img);

    }
    // imgs_div.appendChild(imgcopy)
    // imgs_div.appendChild(img)
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      console.log(rowData)
      copyContent(rowData.phone)
    })
    if(rowData?.phone2!=''&&rowData?.phone2!=null){
      return div;
    }
  }
  const salesField= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');
    let sales=rowData?.sales??[];
    for(let i=0 ;i<sales?.length;i++){
      let saleName=document.createElement('p');
      saleName.innerText=sales[i].name;
      div.appendChild(saleName);
    }
    if(sales.length>0){
      return div
    }
  }
  const salesPhoneField= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');
    let sales=rowData?.sales??[];
    for(let i=0 ;i<sales?.length;i++){
      let saleName=document.createElement('p');
      saleName.innerText=sales[i].phone;
      div.appendChild(saleName);
    }
    if(sales.length>0){
      return div
    }
  }
  const salesEmailField= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');
    let sales=rowData?.sales??[];
    for(let i=0 ;i<sales?.length;i++){
      let saleName=document.createElement('p');
      saleName.innerText=sales[i].user_email;
      div.appendChild(saleName);
    }
    if(sales.length>0){
      return div
    }
  }
  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) +0; // Get the row position and add 1 to make it 1-based
};


const handleChangeStatus=(rowData)=>{
  console.log(rowData)
  if(changeLoading){
    toast.warn('Something in Action Now')
    return
  }
  setChangeLoading(true)
  const data_send={
    client_id:rowData.client_id,
    agent_id,
    status:'no_answer',
    status_label:'No Answer',


  }
  axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/change_status.php",JSON.stringify(data_send))
  .then((res)=>{
  console.log(res)
    if(res.status=='success'){
      toast.success(res.message)
      getNotAnswer()
    }
    else if(res.status=='error'){
      toast.error(res.message)
    }
    else{
      toast.error("Something Went Error")
    }
  })
  .catch(e=>console.log(e))
  .finally(()=>{
    setChangeLoading(false)
  })
}

const NotAnswerBtn = (cell, formatterParams, onRendered) => {
  const row = cell.getRow();
  const rowData = row.getData();

  // Create the button element
  const button = document.createElement("button");
  button.innerText = "Not Answer";
  button.style.cursor = "pointer";
  button?.classList?.add('btn-danger')
  button.style.color='white'
  button.style.backgroundColor='red'
  button.style.border='none'
  button.style.borderRadius='4px'
  button.style.padding='4px'
  // Add click event listener
  button.addEventListener("click", () => {
    // console.log(rowData);
    handleChangeStatus(rowData)
  });

  return button;
};


  const ActionBtn = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const button = document.createElement("button");
    button.innerText = "action";
    button.style.cursor = "pointer";
    button?.classList?.add('btn-success')
    button.style.color='white'
    button.style.backgroundColor='#4eb052'
    button.style.border='none'
    button.style.borderRadius='4px'
    button.style.padding='4px'
    // Add click event listener
    button.addEventListener("click", () => {
      setRowData(rowData);
      setShowNewStatusModal(true)
    });

    return button;
  };
  const statusdiv = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.status!=null){
      pStatus.innerText=rowData?.status;
    }
    // console.log(rowData?.status)


    return pStatus;
  };
  const statusLabeldiv = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.status!=null){
      pStatus.innerText=rowData?.status_label;
    }
    // console.log(rowData?.status)


    return pStatus;
  };
  const Feeddiv = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.status!=null){
      pStatus.innerText=rowData?.feed_back;
    }
    // console.log(rowData?.status)


    return pStatus;
  };

  const sheetLabel = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.sheet!=null){
      pStatus.innerText=rowData?.sheet?.title;
    }
    // console.log(rowData?.status)


    return pStatus;
  };
  const sheetSource = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");

    if(rowData?.sheet!=null){
      pStatus.innerText=rowData?.source_name;
    // console.log(rowData?.sheet?.source)
    }


    return pStatus;
  };
  const nextMeetingForm = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const pStatus = document.createElement("p");


    let p=document.createElement('p');
    let p2=document.createElement('p');
    p.innerText=rowData?.meeting_date&&rowData?.meeting_date+"\n  " +rowData?.meeting_time+ " Hour";
    // p2.innerText=rowData?.time
    pStatus.appendChild(p)
    // pStatus.appendChild(p2)
    return pStatus;
  };

  var headerMenu = function(){
    var menu = [];
    var columns = this.getColumns();

    for(let column of columns){

        //create checkbox element using font awesome icons
        let icon = document.createElement("i");
        icon.classList.add("fas");
        icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

        //build label
        let label = document.createElement("span");
        let title = document.createElement("span");

        title.textContent = " " + column.getDefinition().title;

        label.appendChild(icon);
        label.appendChild(title);

        //create menu item
        menu.push({
            label:label,
            action:function(e){
                //prevent menu closing
                e.stopPropagation();

                //toggle current column visibility
                column.toggle();

                //change menu item icon
                if(column.isVisible()){
                    icon.classList.remove("fa-square");
                    icon.classList.add("fa-check-square");
                }else{
                    icon.classList.remove("fa-check-square");
                    icon.classList.add("fa-square");
                }
            }
        });
    }

   return menu;
};


const handleGetHistoy=(id)=>{
  setHistoryLoading(true)
  const data_send={
    client_id:id,
  }
  axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_history.php",JSON.stringify(data_send))
  .then((res)=>{
    if(res.status=='success'){
      if(Array.isArray(res.message)){
        setClientHistory(res.message)
      }
    }
  console.log(res)

  })
  .catch(e=>console.log(e))
  .finally(()=>{
    setHistoryLoading(false)
  })
}

const HistoryAction = (cell, formatterParams, onRendered) => {
  const row = cell.getRow();
  const rowData = row.getData();

  // Create the button element
  const button = document.createElement("button");
  button.innerText = "History";
  button.style.cursor = "pointer";
  button?.classList?.add('btn-success')
  button.style.color='white'
  button.style.backgroundColor='#0b5ed7'
  button.style.border='none'
  button.style.borderRadius='4px'
  button.style.padding='4px'
  // Add click event listener
  button.addEventListener("click", () => {
    setShowHistoyModel(true)
    // console.log(rowData?.client_id)
    handleGetHistoy(rowData?.client_id)
  });

  return button;
};

const AddMeetingActon = (cell, formatterParams, onRendered) => {
  const row = cell.getRow();
  const rowData = row.getData();

  // Create the button element
  const button = document.createElement("button");
  button.innerText = "Add Meeting";
  button.style.cursor = "pointer";
  button?.classList?.add('btn-success')
  button.style.color='white'
  button.style.backgroundColor='#dc3545'
  button.style.border='none'
  button.style.borderRadius='4px'
  button.style.padding='4px'
  // Add click event listener
  button.addEventListener("click", () => {
    setShowAddMeeting(true)
    setRowData(rowData)
    // console.log(rowData?.client_id)
    // handleGetHistoy(rowData?.client_id)
  });

  return button;
};


const agentField= (cell, formatterParams, onRendered)=>{
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  console.log(rowData)
  const div=document.createElement('div');
  div.innerText=rowData?.agent?.name;
  return div;
}

const agentEmailField= (cell, formatterParams, onRendered)=>{
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const div=document.createElement('div');
  div.innerText=rowData?.agent?.user_email;
  return div;
}

const agentEmailPhone= (cell, formatterParams, onRendered)=>{
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const div=document.createElement('div');
  div.innerText=rowData?.agent?.phone;
  return div;
}
const [comment, setComment] = useState("");
const [commentsData, setCommentsData] = useState([]);

const [meetingData,setMeetingData]=useState({
  date:'',
  time:'',

})

const [oldData, setOldData] = useState(null);
const [deleteComment, setDeleteComment] = useState(null);

const [openComment, setOpenNewCommect] = useState(false);
const handleDeleteComment = (comment_id,client_id) => {
  // setAddLoading(true)
  const data_send = {
    comment_id,
  };
  console.log(data_send,"data_send",rowData)
  // return ;
  axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/delete_comment.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res);
      if (res.status == "success") {
        toast.success(res.message);
        setDeleteComment(null);
        handleGetComments(client_id);
      } else if (res.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("Something Went Error");
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setAddLoading(false);
    });
};
const [ClientId, setClientId] = useState(null);
const [oldComment, setOldComment] = useState(null);

const commentColumns = [
  // Row number column
  {
    title: "Comment",
    field: "comment",
    sorter: "string",
    headerFilter: "input",
    headerMenu,
  },

  // {
  //   title: "Update",
  //   sorter: "string",
  //   headerFilter: "input",
  //   headerMenu,
  // },
  // ,
  // {
  //   title: "duration",
  //   field: "duration",
  //   sorter: "string",
  //   headerFilter: "input",
  //   headerMenu,
  // },
  // // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
  // {
  //   title: "Last Action Date",
  //   field: "date",
  //   sorter: "date",
  //   sorterParams: { format: "YYYY-MM-DD" },
  //   headerFilter: "date",
  //   headerMenu,
  // },
  // {
  //   title: "Agent Name",
  //   field: "agent",
  //   formatter: agentField,
  //   sorter: "string",
  //   headerMenu,
  // },
  // {
  //   title: "Agent Email",
  //   field: "agent",
  //   formatter: agentEmailField,
  //   sorter: "string",
  //   headerMenu,
  // },
  {
    title: "Actions",
    field: "update",
    formatter: (cell) => {
      const row = cell.getRow();
      const rowData = row.getData();
      const div = document.createElement("div");
      const btn = document.createElement("button");
      btn.innerText = "Update";
      btn.style.backgroundColor='#157347';
      btn.style.color='#fff';
      btn.style.border='none';
      btn.style.padding='10px';
      btn.style.borderRadius='4px'
      btn.style.marginRight='4px'
      const btn2 = document.createElement("button");
      btn2.innerText = "Delete";
      btn2.style.backgroundColor='#bb2d3b';
      btn2.style.color='#fff';
      btn2.style.border='none';
      btn2.style.padding='10px';
      btn2.style.borderRadius='4px'
      btn2.style.marginRight='4px'
      btn.addEventListener("click", () => {
        const value = cell.getValue();
        setOldComment(rowData);
        setOldData(rowData);
      });
      btn2.addEventListener("click", () => {
        setDeleteComment((prev) => ({ ...prev, rowData }));
        handleDeleteComment(rowData?.comment_id);
        console.log(rowData)
      });
      div.appendChild(btn);
      div.appendChild(btn2);
      const table = cell.getTable(); // Get the table instance
      const rowIndex = row.getPosition(); // Get the row index
      const totalRows = table.getRows().length;
      if(rowIndex === totalRows ){
        return div;
      }
    },
    sorter: "string",
    headerMenu,
  },
];

const handleGetComments = async (id) => {
  setCallLoading(true);
  const data_send = {
    client_id: id,
  };
  await axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_comments.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res.message);
      if (res.status == "success") {
        if (Array.isArray(res.message)) {
          setCommentsData(res.message);
        }
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setCallLoading(false);
    });
};
const handleAddComment = () => {
  // setAddLoading(true)
  console.log(ClientId)
  const data_send = {
    comment: comment,
    client_id: ClientId?.client_id,
    agent_id: uData?.agent_id,
  };
  console.log(data_send);
  // return ;
  axios
    .post(
      "https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_comment.php",
      JSON.stringify(data_send)
    )
    .then((res) => {
      console.log(res);
      if (res.status == "success") {
        toast.success(res.message);
        setNewCall({ duration: "", feed_back: "" });
        handleGetComments(ClientId?.client_id);
        setOpenNewCommect(false);
        setComment('')
        setClientId(null)
        setShowAddModal(false);
      } else if (res.status == "error") {
        toast.error(res.message);
      } else {
        toast.error("Something Went Error");
      }
    })
    .catch((e) => console.log(e))
    .finally(() => {
      setAddLoading(false);
    });
};
const commentField = (cell, formatterParams, onRendered) => {
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const div = document.createElement("div");
  div.classList?.add("columnDiv")
  const btn = document.createElement("button");
  const btn2 = document.createElement("button");
  btn.innerText = "Comments";
  btn2.innerText = "Profile";
  btn2.style.color = "white !important";
  btn2.style.backgroundColor = "#0b5ed7";
  btn2.style.color = "#fff";
  btn2.style.border = "none";
  btn2.style.padding = "10px";
  btn2.style.borderRadius = "4px";
  btn.style.color = "white !important";
  btn.style.backgroundColor = "#0b5ed7";
  btn.style.color = "#fff";
  btn.style.border = "none";
  btn.style.padding = "10px";
  btn.style.borderRadius = "4px";
  btn.addEventListener("click", function () {
    handleGetComments(rowData?.client_id);
    setRowData(rowData?.client_id);
    setClientId(rowData);
  });
  div.appendChild(btn);
  div.appendChild(btn2);
  btn2.addEventListener("click", function () {
    window.location.href = "/UserProfile/" + rowData?.client_id;
  });
  return div;
};

const selectForm = (cell, formatterParams, onRendered) => {
  const value = cell.getValue();
  const row = cell.getRow();
  const rowData = row.getData();
  const check_box = document.createElement("input");
  check_box.type = "checkbox";
  check_box.checked = rowData?.check;
  check_box.style.width = "20px";
  check_box.style.height = "20px";
  check_box.style.cursor = "pointer";
  check_box.addEventListener("click", function () {
    setData(
      data.map((item) => {
        return {
          ...item,
          check:
            item.client_id == rowData.client_id ? !item.check : item.check,
        };
      })
    );
  });
  if(true){//uData?.user_type!='sells'
    return check_box;
  }
  // check_box.
};


  // const columns =

  const historyColumns=[
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center',headerMenu }, // Row number column
    { title: 'status', field: 'status', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'status_label', field: 'status_label', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'Last Action Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD H:i:s' }, headerFilter: "date" ,headerMenu},
    { title: 'Agent Name', field: 'agent',formatter:agentField, sorter: 'string',headerMenu},
    { title: 'Call Back Date', field: 'call_back_date', sorter: 'string',headerMenu},
    { title: 'Call Back Time', field: 'call_back_time', sorter: 'string',headerMenu},
    { title: 'Agent Email', field: 'agent',formatter:agentEmailField, sorter: 'string', headerMenu},
    { title: 'Agent Phone', field: 'agent',formatter:agentEmailPhone, sorter: 'string', headerMenu},
  ];



  const callColumns=[
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center',headerMenu }, // Row number column
    { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'duration', field: 'duration', sorter: 'string', headerFilter: "input",headerMenu },
    // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'Last Action Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" ,headerMenu},
    { title: 'Agent Name', field: 'agent',formatter:agentField, sorter: 'string',headerMenu},
    { title: 'Agent Email', field: 'agent',formatter:agentEmailField, sorter: 'string', headerMenu},
    { title: 'Agent Phone', field: 'agent',formatter:agentEmailPhone, sorter: 'string', headerMenu},
  ];

  // useEffect(()=>{
  //   if(uData?.user_type=='sells'){
  //     setColumns()
  //   }
  //   else {
  //     setColumns([
  //       // { title: 'ID', field: 'client_id', sorter: 'number', headerFilter: "input" },
  //       { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center' ,headerMenu}, // Row number column
  //       { title: 'Select', field: 'select', formatter: function(cell, formatterParams, onRendered) {
  //         // Create a checkbox element
  //         const input = document.createElement('input');
  //         input.style.width='20px';
  //         input.style.height='20px';
  //         input.type = 'checkbox';
  //         input.checked = cell.getValue();
  //         input.addEventListener('change', function() {
  //           cell.setValue(input.checked);
  //           const updatedData = data.map((row) =>
  //             row.id === cell.getData().id ? { ...row, check: cell.getValue() } : row
  //           );
  //           setData(updatedData);
  //         });
  //         // Return the checkbox element
  //         return input;
  //       }, align: 'center', cellClick: function(e, cell) {
  //         // Toggle the checkbox

  //         cell.setValue(!cell.getValue());
  //       }
  //     },
  //       { title: 'Name', field: 'name', sorter: 'string', headerFilter: "input" ,headerMenu},
  //       { title: 'Phone', field: 'phone',formatter:phone1lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone",headerMenu  },
  //       { title: 'Phone2', field: 'client_phone2',formatter:phone2lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" ,headerMenu},
  //       { title: 'project_intrested_in', field: 'project_intrested_in', sorter: 'string', headerFilter: "input" ,headerMenu},
  //       {
  //         title: "Comments",
  //         field: "phone",
  //         formatter: commentField,
  //         headerFilterPlaceholder: "phone",
  //         headerMenu,
  //       },
  //       { title: 'Action', field: 'client_name',formatter:ActionBtn, width: 100,headerMenu },
  //       { title: 'Calls', field: 'phone',formatter:callField,  headerFilterPlaceholder: "phone" ,headerMenu },
  //       { title: 'History', field: 'client_name',formatter:HistoryAction, width: 100 },
  //       { title: 'sale Name', field: 'client_phone2',formatter:salesField, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" ,headerMenu},
  //       { title: 'sale Email', field: 'client_phone2',formatter:salesEmailField, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" ,headerMenu},
  //       { title: 'sale Phone', field: 'client_phone2',formatter:salesPhoneField, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" ,headerMenu},
  //       { title: 'time_to_call', field: 'time_to_call', sorter: 'string', headerFilter: "input" ,headerMenu},
  //       { title: 'Email', field: 'email', sorter: 'string', headerFilter: "input" ,headerMenu},
  //       { title: 'unitcode', field: 'unitcode', sorter: 'string', headerFilter: "input" ,headerMenu},
  //       { title: 'Added Date', field: 'added_at', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD ' }, headerFilter: "date" ,headerMenu},
  //       { title: 'Last Action Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD H:i:s' }, headerFilter: "date" ,headerMenu},

  //       // { title: 'Status', field: 'added_at',formatter:statusdiv,headerMenu},
  //       // { title: 'Status Label', field: 'added_at',formatter:statusLabeldiv,headerMenu},
  //       { title: 'Feed Back', field: 'added_at',formatter:Feeddiv,headerMenu},
  //       { title: 'Label', field: 'added_at',formatter:sheetLabel,headerMenu},
  //       { title: 'Source', field: 'added_at',formatter:sheetSource,headerMenu},
  //       { title: 'next Meeting', field: 'added_at',formatter:nextMeetingForm,headerMenu},


  //       // { title: 'Address', field: 'client_address', sorter: 'string', headerFilter: "input" ,headerMenu},
  //       // { title: 'No Answer', field: 'client_name',formatter:NotAnswerBtn, width: 100 ,headerMenu},
  //       // { title: 'Add Meetings', field: 'client_name',formatter:AddMeetingActon, width: 100 },
  //       { title: 'Address', field: 'address', sorter: 'string', headerFilter: "input" ,headerMenu},
  //       // { title: 'Added Date', field: 'added_at', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" },
  //       // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
  //     ])
  //   }
  // },[])


  const geteployees = () => {
    axios
      .get(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_selles.php"
      )
      .then((res) => {
        //console.log(res)
        //console.log(res);
        setemployeessdata(res.message);
        setselectedemployee(res?.message[0]?.agent_id);
        //console.log(res?.message[0]?.name);
      });
  };
  const [data2,setData2]=useState([{name:'abduu'}])
  const colls=[
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center' ,headerMenu}, // Row number column


    { title: `Name `, field: 'name', sorter: 'string',  headerFilter: "input" ,topCalc:"count"},
    { title: 'Phone', field: 'phone',formatter:phone1lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone",headerMenu  ,topCalc:"count"},
    { title: 'Phone2', field: 'client_phone2',formatter:phone2lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" ,headerMenu,topCalc:"count"},
    { title: 'project_intrested_in', field: 'project_intrested_in', sorter: 'string', headerFilter: "input" ,headerMenu,topCalc:"count"},
    {
      title: "Comments",
      field: "phone",
      formatter: commentField,
      headerFilterPlaceholder: "phone",
      headerMenu,
      // topCalc:"count"
    },
    { title: 'Action', field: 'client_name',formatter:ActionBtn, width: 100,headerMenu },
    { title: 'Calls', field: 'phone',formatter:callField,  headerFilterPlaceholder: "phone" ,headerMenu },
    { title: 'History', field: 'client_name',formatter:HistoryAction, width: 100 },
    { title: 'time_to_call', field: 'time_to_call', sorter: 'string', headerFilter: "input" ,headerMenu,topCalc:"count"},
    { title: 'Email', field: 'email', sorter: 'string', headerFilter: "input" ,headerMenu,topCalc:"count"},
    { title: 'unitcode', field: 'unitcode', sorter: 'string', headerFilter: "input" ,headerMenu,topCalc:"count"},
    { title: 'Added Date', field: 'added_at', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD ' }, headerFilter: "date" ,headerMenu,topCalc:"count"},
    { title: 'Last Action Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD H:i:s' }, headerFilter: "date" ,headerMenu,topCalc:"count"},
    { title: 'Feed Back', field: 'added_at',formatter:Feeddiv,headerMenu,topCalc:"count"},
    { title: 'Label', field: 'added_at',formatter:sheetLabel,headerMenu,topCalc:"count"},
    { title: 'Source', field: 'added_at',formatter:sheetSource,headerMenu,topCalc:"count"},
    { title: 'next Meeting', field: 'added_at',formatter:nextMeetingForm,headerMenu,topCalc:"count"},
    { title: 'Address', field: 'address', sorter: 'string', headerFilter: "input" ,headerMenu,topCalc:"count"},
  ];

  const getNotAnswer=()=>{
    setpageloading(true)
    const data_send={
      agent_type:uData.user_type,
      agent_id:uData.agent_id,
      status:'interested',
      client_type:"all",


      type:uData.user_type,
      // agent_id:uData.agent_id,
        // status:'interested',//
      // client_type:"all",

      // page:page
    }
    console.log(data_send)
    //get_other_agent_client_status
    axios.post('https://camp-coding.tech/new_quad_wall/quad_crm/admin/new_client_by_status.php',JSON.stringify(data_send))
    .then((res)=>{
      console.log(res,"this is may response")
      if(Array.isArray(res.message)){
        setData(res.message.map((item)=>{
          return {...item,check:false,image:require("../../assets/images/whats.png"),copyimg:require("../../assets/images/copy.png")}
        }))
        setOriginalData(res.message.map((item)=>{
          return {...item,check:false,image:require("../../assets/images/whats.png"),copyimg:require("../../assets/images/copy.png")}
        }))


      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setpageloading(false)
    })
  }

  const handleUpdateComment = () => {
    // setAddLoading(true)
    const data_send = {
      ...oldData,
    };
    console.log(rowData,"wkwek",ClientId)
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/update_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setOldComment(null);
          handleGetComments(ClientId?.client_id);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const handleAddCall = () => {
    // setAddLoading(true)
    const data_send = {
      ...newCall,
      client_id: rowData?.client_id||ClientId.client_id,
      agent_id: authUser?.agent_id,
    };
    console.log(data_send);
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/add_new_call.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setNewCall({ duration: "", feed_back: "" });
          handleGetCalls(rowData?.client_id);
          setShowAddModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const handleAddMeeting=()=>{
    setMeetingLoading(true)
    const data_send={
      ...rowData,
      agent_id,
      // client_status_id:rowData?.
      ...meetingData,
    }
    console.log(data_send)
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/add_new_meeting.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        getNotAnswer()
        setShowAddMeeting(false)
      }

      else if(res.status=='faild'){
        toast.error(res.message)
      }
      else{
        toast.error('Something Went Error')
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setMeetingLoading(false)
    })
  }


  const handleOk=()=>{
    console.log(rowData)
    setLoading(true)
    setAddLoading(true)
    if(changeLoading){
      toast.warn('Something in Action Now')
      return
    }
    setChangeLoading(true)
    const data_send={
      client_id:rowData.client_id,
      agent_id,
      status:newStatus,
      feedBack,
      ...callBackData,
      status_label:statusOptions.filter(it=>it.value==newStatus)[0].label,
    }
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/change_status.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        getNotAnswer()
        setCallBackData({
          time:'',
          date:'',
        })
        setShowNewStatusModal(false)
        setFeedBack('')
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else{
        toast.error("Something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setChangeLoading(false)
    setLoading(false)
    setAddLoading(false)
    })
  }


  const reAssisgnFunc=()=>{
    let selected=[...data];
    let checddata=selectedRows;
    let selectedtxt="";
    if(selectedemployee==""){
      toast.warn("select at least one employee")
      return
    }
    for(let i=0;i<checddata.length;i++){
      if(i==0){
        selectedtxt+=checddata[i].client_id;
      }
      else {
        selectedtxt+="**UAD**"+checddata[i].client_id;
      }
    }
    const data_send={
      client_id_data:selectedtxt,
      agent_id:selectedemployee,
      operation_id:uData.agent_id
    }
    console.log(data_send);
    // return
    setassignloading(true);
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/reassign_agent_to_client.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(res.status==="success"){
        let alldata=[...data];
        alldata.map((item)=>{
          return {...item,check:false};
        })
        //retnotchecked();
        setData(alldata)
        setShowModal(false)
        toast.success(res.message);
        getNotAnswer();
      }
      else if(res.status=="error"){
        toast.error(res.message);
      }
      else {
        toast.error("something is error try again")
      }
    }).finally(()=>{
      setassignloading(false);
    })
  }

  const unAssignCleints=()=>{
    console.log(data)
    setUnAssLoading(true)
    let selectedtxt='';
    const selected=selectedRows
    for(let i=0;i<selected.length;i++){
      if(i==0){
        selectedtxt+=selected[i].client_id;
      }
      else {
        selectedtxt+="**UAD**"+selected[i].client_id;
      }
    }
    if(selectedtxt==''){
      toast.warn('Select From Unassign To Make Request')
      return;
    }
    const data_send={
      client_id_data:selectedtxt,
    }
    console.log(data_send);
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/unassign_clients.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        setShowModal(false)

        getNotAnswer()
        // setData(data.map((it)=>{
        //   return {...it}
        // }));
      }
      else if(res.status=='error') {
        toast.error(res.message)
      }
      else {
        toast.error(res.message)
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
    setUnAssLoading(false)
    })
  }
  useEffect(()=>{
    setData(data.map((item)=>{
      return{...item,check:selectAll?true:false}
    }))
    // updateColumnTitles()
  },[selectAll])

  useEffect(()=>{
    getNotAnswer()
    geteployees()
  },[])


  useEffect(()=>{
    // console.log(data)
    let selected=data.filter(it=>it.check)
    if(selected.length>0){
      setDisConf(false)
    }
    else {
      setDisConf(true)
    }

  },[data])



  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<AiOutlineSearch/>}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <AiOutlineSearch
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });



  const renderusers = [
    {
      title: 'Number',
      key: 'number',
      width: '10%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },

    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      render: (_, record) => (
        <Space>
        <div>
          {
            record.phone!=null&&record.phone!=""&&
            <div>
                <img style={{width:'30px'}} onClick={()=>{
                      window.open(`whatsapp://send?text=!&phone=+2${record.phone.startsWith("0")?record.phone:`${record.phone}`}`,'_blank')
              }} src={require("../../assets/images/whats.png")} alt="" />
              <img style={{width:'30px'}} onClick={()=>{
                      copyContent(record.phone)
              }} src={require("../../assets/images/copy.png")} alt="" />
              <p>{record.phone}</p>
            </div>
          }
        </div>
      </Space>
        )
    },
    {
      title: 'phone2',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      ...getColumnSearchProps('phone2'),
      render: (_, record) => (
        <Space>
        <div>
          {
            record.phone2!=null&&record.phone2!=""&&
            <div>
                <img style={{width:'30px'}} onClick={()=>{
                      window.open(`whatsapp://send?text=!&phone=+2${record.phone2.startsWith("0")?record.phone2:`${record.phone2}`}`,'_blank')
              }} src={require("../../assets/images/whats.png")} alt="" />
              <img style={{width:'30px'}} onClick={()=>{
                      copyContent(record.phone2)
              }} src={require("../../assets/images/copy.png")} alt="" />
              <p>{record.phone2}</p>
            </div>
          }
        </div>
      </Space>
        ),
    },

    {
      title: 'project_intrested_in',
      dataIndex: 'project_intrested_in',
      key: 'project_intrested_in',
      width: '30%',
      ...getColumnSearchProps('project_intrested_in'),
    },

    {
      title: 'Comments',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" ,rowGap:'5px'}}>
            <button onClick={()=>{
            handleGetComments(record?.client_id);
            setRowData(record?.client_id);
            setClientId(record);
            }} className="btn btn-primary">
              Comments
            </button>
            <button onClick={()=>{
                window.location.href = "/UserProfile/" + record?.client_id;
            }} className="btn btn-primary">
              Profile
            </button>
          </div>
        </Space>
        )
    },

    {
      title: 'Calls',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <button onClick={()=>{
              handleGetCalls(record?.client_id);
              setRowData(record?.client_id);
              setShowCalls(true);
            }} className="btn btn-primary">
              Calls
            </button>
          </div>
        </Space>
        )
    },

    // {
    //   title: 'No Answer',
    //   dataIndex: 'phone2',
    //   key: 'phone2',
    //   width: '20%',
    //   render: (_, record) => (
    //     <Space>
    //       <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
    //         <button onClick={()=>{
    //           handleChangeStatus(record)

    //         }} className="btn btn-primary">
    //           Not Answer
    //         </button>
    //       </div>
    //     </Space>
    //     )
    // },
    {
      title: 'Action',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <button onClick={()=>{
                setRowData(record);
                setShowNewStatusModal(true)

            }} className="btn btn-primary">
              Action
            </button>
          </div>
        </Space>
        )
    },
    {
      title: 'History',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <button onClick={()=>{
                  setShowHistoyModel(true)
                  // console.log(rowData?.client_id)
                  handleGetHistoy(record?.client_id)

            }} className="btn btn-primary">
              History
            </button>
          </div>
        </Space>
        )
    },
    {
      title: 'Add Meetings',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <button onClick={()=>{
                    setShowAddMeeting(true)
                    setRowData(record)

            }} className="btn btn-primary">
              Add Meetings
            </button>
          </div>
        </Space>
        )
    },


    {
      title: 'Select',
      dataIndex: 'name',
      key: 'sele',
      width: '30%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <input onClick={()=>{
              setData(data.map((item)=>{
                return {...item,check:item.client_id==record.client_id?!record.check:item.check}
              }))
            }} checked={record.check} type="checkbox" style={{ width:'15px',height:'15px' }}/>
          </div>
        </Space>
        )
    },
    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
    },
    // {
    //   title: 'Last Action',
    //   dataIndex: 'last_action_date',
    //   key: 'last_action_date',
    //   width: '20%',
    //   ...getColumnSearchProps('last_action_date'),
    // },
    {
      title: 'Time To Call',
      dataIndex: 'time_to_call',
      key: 'time_to_call',
      width: '20%',
      ...getColumnSearchProps('time_to_call'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ...getColumnSearchProps('email'),
    },


    {
      title: 'Feed Back',
      dataIndex: 'feed_back',
      key: 'feed_back',
      width: '20%',
      ...getColumnSearchProps('feed_back'),
      render: (_, record) => (
        <Space>
          <div>
            {
                <p>{record.feed_back}</p>

              }
          </div>
        </Space>
        ),
    },
    {
      title: 'Source',
      dataIndex: 'feed_back',
      key: 'feed_back',
      width: '20%',
      ...getColumnSearchProps('feed_back'),
      render: (_, record) => (
        <Space>
          {  console.log(record)}
          <div>
            {

                <p>{record.source_name}</p>


            }
          </div>
        </Space>
        ),
    },
    {
      title: 'next Meeting',
      dataIndex: 'feed_back',
      key: 'feed_back',
      width: '20%',
      ...getColumnSearchProps('feed_back'),
      render: (_, record) => (
        <Space>
          <div>
            <p>{record?.meeting_date&&record?.meeting_date+"\n  " +record?.meeting_time+ " Hour"}</p>
          </div>
        </Space>
        ),
    },


    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ...getColumnSearchProps('address'),
      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ['descend', 'ascend'],
    },
  ];


  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      editable: true,
      filter: true,
      floatingFilter: true,
      onCellValueChanged: (e) => console.log(e),
      // flex: 10,
      width:'fit-content'
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => {
        // setRowData(data);
      });
  }, []);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows())
    if(event.api.getSelectedRows().lengt>0){
      setDisConf(true);
    }
    else {
      setDisConf(false);
    }
  };
  const isRowSelectable = (node) => {
    return true; // or node.data.<column>
  };
  const onExportClick = () => {
    // gridApi.api.exportDataAsCsv();
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData)
    const params = {
      fileName: 'selected_rows.csv',
      columnSeparator: ',',
      skipHeader: false,
      columnKeys: ['make', 'model', 'price']
    };
    const pp = [];
    selectedData.map((item, index) => {
      if (true) {
        console.log('dsd');
        let newobj = {
          'Client name': item.name || '',
          'Client phone': item.phone || '',
          'Client phone2': item.phone2 || '',
          'Client project_intrested_in': item.project_intrested_in || '',
          'Client time_to_call': item.time_to_call || '',
          'Client unitcode': item.unitcode || '',
          'Client client_type': item.client_type || '',
          'Client status': item.status || '',
          'Client status label': item.status_label || '',
          'Client job': item.job || '',
          'Client address': item.address || '',
          'agent name': item?.sales[0].name || '',
          'agent Email': item?.sales[0].user_email || '',
          'agent Phone': item?.sales[0].phone || '',
          'feed_back':item.feed_back,
          "creation_date":moment(item.added_at).format("L"),
          "last_action_date":moment(item.last_action_date).format("L"),
          "markter name":item.marketing_name,
          "last_feed_call":item.last_feed_call,
        };
        pp.push(newobj);
      }
    });
    if (selectedData.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    // setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // gridRef.current.api.exportDataAsCsv(params, {
    //   rowData: selectedData
    // });
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'Name',
      field: "name",
      aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "project intrested in",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.project_intrested_in}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },

    {
      headerName: "",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params?.data&&params?.data?.phone!=null&&params?.data.phone!=""&&
          <div>
                <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params?.data&&params.data.phone)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone",
      field: "phone",
      aggFunc:"sum",

      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params.data&&params.data.phone!=null&&params.data.phone!=""&&
          <div>
            <p>{params.data.phone}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "",
      field: "phone2",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {console.log(params.data)}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
                          <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params.data.phone2)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone2",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
            <p>{params.data.phone2}</p>
          </div>
        }
      </div>
      ),
    },

    {
      headerName: "Comments",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetComments(params?.data?.client_id);
                setRowData(params.data);
                console.log(params?.data)
                setClientId(params?.data);
              }}
            >Comments</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Calls",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetCalls(params.data?.client_id)
                setRowData(params.data)
                setShowCalls(true)
              }}
            >Calls</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Profile",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                window.location.href = "/UserProfile/" + params?.data?.client_id;

              }}
            >Profile</button>
      ),
      sortable: false,
    },
    {
      headerName: "History",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                setShowHistoyModel(true)
                console.log(params.data,"paramsparams")
                handleGetHistoy(params?.data?.client_id)
              }}
            >History</button>
      ),
      sortable: false,
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
    },
    // {
    //   headerName: 'feed back',
    //   field: "feed_back",
    //   aggFunc:"sum",
    //   // tooltipField:"age",
    // },
    {
      headerName: "sale name",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].name}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale email",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].user_email}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale phone",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].phone}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "address",
      field: "address",
      sortable: true,
    },
    {
      headerName: "added at",
      field: "added_at",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "last_action_date",
      field: "last_action_date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "last action date",
      field: "added_at",
      sortable: true,
      hide: true,
    },
    {
      headerName: "Feed Back",
      field: "feed_back",
      sortable: true,
    },
  ]);
  const [salesColsDefs,setSalesColsDefs]=useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'Name',
      field: "name",
      aggFunc:"sum",
      // tooltipField:"age",
    },

    {
      headerName: "",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params?.data&&params?.data?.phone!=null&&params?.data.phone!=""&&
          <div>
                <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params?.data&&params.data.phone)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone",
      field: "phone",
      aggFunc:"sum",

      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params.data&&params.data.phone!=null&&params.data.phone!=""&&
          <div>
            <p>{params.data.phone}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "",
      field: "phone2",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {console.log(params.data)}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
                          <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params.data.phone2)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone2",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
            <p>{params.data.phone2}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "project intrested in",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.project_intrested_in}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },
    {
      headerName: "Action",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          <div>
            <button onClick={()=>{
                setRowData(params.data);
                setShowNewStatusModal(true)
            }} className='btn btn-primary'>Action</button>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "No Answer",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          <div>
            <button onClick={()=>{
              handleChangeStatus(params.data)
            }} className='btn btn-danger'>No Answer</button>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "added at",
      field: "added_at",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "last_action_date",
      field: "last_action_date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "Comments",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetComments(params?.data?.client_id);
                setRowData(params.data);
                console.log(params?.data)
                setClientId(params?.data);
              }}
            >Comments</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Calls",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetCalls(params.data?.client_id)
                setRowData(params.data)
                setShowCalls(true)
              }}
            >Calls</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Profile",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                window.location.href = "/UserProfile/" + params?.data?.client_id;

              }}
            >Profile</button>
      ),
      sortable: false,
    },
    {
      headerName: "History",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                setShowHistoyModel(true)
                console.log(params.data,"paramsparams")
                handleGetHistoy(params?.data?.client_id)
              }}
            >History</button>
      ),
      sortable: false,
    },
    // {
    //   headerName: "No Answer",
    //   field: "phone2",
    //   aggFunc:"sum",
    //   sortable: true,
    //   // hide: true,
    //   cellRenderer: (params) => (
    //     <div>
    //       {/* {console.log(params.data)} */}
    //     {
    //       <div>
    //         <button onClick={()=>{
    //           handleChangeStatus(params.data)
    //         }} className='btn btn-danger'>No Answer</button>
    //       </div>
    //     }
    //   </div>
    //   ),
    // },

    {
      headerName: "Add meeting",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          <div>
            <button onClick={()=>{
                    setShowAddMeeting(true)
                    setRowData(params?.data)

            }} className="btn btn-primary">
              Add Meetings
            </button>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
    },



    {
      headerName: "last action date",
      field: "added_at",
      sortable: true,
      hide: true,
    },
    {
      headerName: "address",
      field: "address",
      sortable: true,
    },
    {
      headerName: "Feed Back",
      field: "feed_back",
      sortable: true,
    },

  ])
  const [columnComsDefs, setColumnComsDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'comment',
      field: "comment",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "update",
      // aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) =>{
        const rowIndex = params.node.rowIndex;
        const displayedRowCount = params.api.getDisplayedRowCount();
        const isLastRow = rowIndex === displayedRowCount - 1;
        return (
          <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
          {/* {console.log(params,"ekllerk")} */}

            {
              isLastRow&&
              <button className="btn btn-success"
              onClick={()=>{
                setOldComment(params?.data);
                setOldData(params?.data);
              }}
              >Update</button>
            }

              {console.log(rowData,"ewklewkllkwe")}
        </div>
        )
      },
      sortable: false,
    },
    {
      headerName: "delete",
      // aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => {
        const rowIndex = params.node.rowIndex;
        const displayedRowCount = params.api.getDisplayedRowCount();
        const isLastRow = rowIndex === displayedRowCount - 1;

        return (
          <div style={{ flexDirection: 'column' }} className='d-flex flex-column gap-3'>
            {console.log(params, "ekllerk")}
            {isLastRow && (
              <button onClick={() => {
                let data = params?.data;
                console.log("Current Row Number:", rowIndex);
                setDeleteComment((prev) => ({ ...prev, data, rowIndex })); // Optionally store rowIndex
                handleDeleteComment(params?.data?.comment_id,params?.data?.client_id);
                setRowData(params?.data);
              }} className='btn btn-danger'>
                Delete
              </button>
            )}
          </div>
        );
      },
      sortable: false,
    },
  ]);

  const [columnCallsDefs, setColumnCallsDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },

    {
      headerName: 'Feed Back',
      field: "feed_back",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'Call Date',
      field: "date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "sale Name",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.name}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale Email",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.user_email}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale phone",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.phone}</p>
            </div>
          }
        </div>
      ),
    },


  ]);
  const [columnsCallDefsSales,setColumnCallsDefsSales]=useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },

    {
      headerName: 'Feed Back',
      field: "feed_back",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'Call Date',
      field: "date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      // aggFunc:"sum",
      // tooltipField:"age",
    },

  ])


  const [columnHisDefs, setColumnHisDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'status',
      field: "status",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'status_label',
      field: "status_label",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'feed_back',
      field: "feed_back",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'call_back_time',
      field: "call_back_time",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'call_back_date',
      field: "call_back_date",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "sale Name",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.name}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale Email",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.user_email}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale phone",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data&&params?.data?.agent?.phone}</p>
            </div>
          }
        </div>
      ),
    },


  ]);

  const [columnHisSelsDefs, setColumnHisSelsDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      // aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'status',
      field: "status",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'status_label',
      field: "status_label",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'feed_back',
      field: "feed_back",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'call_back_time',
      field: "call_back_time",
      // aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'call_back_date',
      field: "call_back_date",
      // aggFunc:"sum",
      // tooltipField:"age",
    },


  ]);

  return (
    <>
      {
        pageloading?(
          [1, 2, 3].map(x => (
            <Col xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ):(
          <div style={{
            marginTop:'30px',
            overflow:'auto',
            minWidth:'100%'
          }} className='py-5 container'>

      {/* <div className='my-2 p-2'>
        <input placeholder='Search' className='form-control' type="text" onChange={(e)=>{
          e.preventDefault();
          handleSearch(e.target.value)
        }} />
      </div> */}
{/*
      <div
            className="d-flex row-gap-2 flex-wrap flex-direction-column"
            style={{ margin: "40px 0px" }}
          >
            <Select
              style={{ width: "100%" }}
              // style={{

              // }}
              value={selectedemployee}
              onChange={(e) => {
                //console.log(e)
                setselectedemployee(e);
              }}
              options={employeessdata.map((item, index) => {
                return { label: `${item.name}`, value: `${item.agent_id}` };
              })}
            />
            <Button
              style={{ display: "block", margin: "10px auto" }}
              onClick={() => {
                assignfunction();
              }}
              className="btn btn-primary"
            >
              {assignloading ? <Spin /> : "assign"}
            </Button>
          </div> */}


      {
        uData?.user_type=='operation'||uData?.user_type=='team_leader'&&  <div className='d-flex align-items-center gap-1 justify-content-end mb-2'>
        <div className='my-3'>
          <button
            disabled={disConf}
            className='btn btn-success'
            onClick={()=>{
              let selected=data.filter(it=>it.check);
              if(selectedRows.length==0){
                toast.warn('Select Cleints First');
                return
              }
              setShowModal(true)
            }}
          >Confirm</button>
        </div>
      </div>
      }


          {
            uData?.user_type!='sells'&&  <div className='d-flex align-items-center justify-content-between'>
            {/* <div className='d-flex align-items-center gap-2 my-2'>
              <h4>Select All</h4>
              <input onChange={(e)=>{
                setSelectAll(!selectAll)
              }} checked={selectAll} style={{
                width:'15px',
                height:'15px',

              }} type="checkbox" />
            </div> */}
            <div className="my-3">
              <button
                onClick={() => {
                  onExportClick()
                  // handleExport();
                }}
                className="btn btn-primary text-light"
              >
                Export
              </button>
            </div>
          </div>
          }
            {/* <Table style={{ marginTop:'10px' }} dataSource={data} columns={renderusers} /> */}

        {/* <ReactTabulator
          data={data}
          options={
            options
          }
          // sortOrderReverse={true}
          columns={colls}
          layout={"fitData"}
        /> */}
          <div   style={gridStyle}
              className={
                "ag-theme-quartz-dark"
              }>
                {/* <div className="pagination my-4">
                          <button
                            className="btn btn-danger mx-2"
                            onClick={() => {}}
                          >
                            Prev
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={() => {}}
                          >
                            Next
                          </button>
                        </div> */}
            <AgGridReact
            // autoGroupColumnDef ={}
            onFilterChanged={()=>{
               onFilterChanged()
            }}
            enableAdvancedFilter={true}
            grandTotalRow={"top"}
                ref={gridRef}
                rowData={data}
                // sideBar={true}
                columnDefs={uData?.user_type=='sells'?salesColsDefs:columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                rowSelection="multiple"
                // columnsi
                rowDragManaged={true}
                tooltipShowDelay={{tooltipShowDelay: 2}}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                // ref={gridRef}
                isRowSelectable={isRowSelectable}
          // pagination={true}
          // quickFilterText={quickSearchValue}
              />

          </div>

<Modal title="Change Client Status" open={showNewStatusModal} onOk={()=>{
        if(addLoading){
          toast.warn('request is running now Please Wait');
          return
        }
        // handleAddClient()
        // handleOk()
        handleOk()
      }} onCancel={()=>{
        setShowNewStatusModal(false)
      }} centered>
        <div>
          <label htmlFor="">New Status</label>
          <select value={newStatus} onChange={(e)=>{
            setNewStatus(e.target.value)
          }} style={{width:'100%',padding:'4px',border:'1px solid #ccc',borderRadius:'10px',backgroundColor:'transparent'}} name="" id="">
            {
              statusOptions&&statusOptions.map((item,index)=>{
                return(
                  <option value={item.value}>{item.label}</option>
                )
              })
            }
          </select>
        </div>
        <div>
          {
            newStatus=='call_back'&&
            <div className='my-2 feed_back'>
              <div>
                <label htmlFor="">Date</label>
                <input type="date" onChange={(e)=>{
                  setCallBackData({
                    ...callBackData,
                    date:e.target.value
                  })
                }} />
              </div>
              <div>
                <label htmlFor="">Time</label>
                <input type="time" onChange={(e)=>{
                  setCallBackData({
                    ...callBackData,
                    time:e.target.value
                  })
                }} />
              </div>
            </div>
          }
        </div>
        <div>
          {
            true&&
            <div className='my-2 feed_back'>
              <label htmlFor="">Feed Back</label>
              <input value={feedBack} placeholder='FeedBack' type="text" onChange={(e)=>{
                setFeedBack(e.target.value)
              }} />
            </div>
          }
        </div>
      </Modal>



      <Modal className='client_history_model' style={{ width:'90vw' }} title="Client History" open={showHistoyModel} onOk={()=>{
        setShowHistoyModel(false)
      }} onCancel={()=>{
        setShowHistoyModel(false)
      }} centered>
        {
          historyLoading?
          <div style={{
            minHeight:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          :
          <>
          {console.log(ClientHistory,"ClientHistory")}
            {/* <ReactTabulator
              data={ClientHistory}
              options={
                options
              }
              columns={historyColumns}
              layout={"fitData"}
              /> */}
              <div style={gridStyle} className="ag-theme-quartz-dark">
                <AgGridReact
                // autoGroupColumnDef ={}
                    onFilterChanged={()=>{
                  onFilterChanged()
                }}
                enableAdvancedFilter={true}
                grandTotalRow={"top"}
                    // ref={gridRef}
                    rowData={ClientHistory}
                    // sideBar={true}
                    columnDefs={uData?.user_type=='sells'?columnHisSelsDefs:columnHisDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    rowSelection="multiple"
                    // columnsi
                    rowDragManaged={true}
                    tooltipShowDelay={{tooltipShowDelay: 2}}
                    onSelectionChanged={onSelectionChanged}
                    rowMultiSelectWithClick={true}
                  />
              </div>
              {console.log(ClientHistory,"historyColumns")}
          </>
        }
      </Modal>

      <Modal className='client_history_model' style={{ width:'90vw' }} title="Client Calls" open={showCalls} onOk={()=>{
        setShowCalls(false)
      }} onCancel={()=>{
        setShowCalls(false)
      }} centered>
        {
          callLoading?
          <div style={{
            minHeight:'30vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          :
          <>
            <div style={{width:'100%',cursor:'pointer',justifyContent:'end'}} className='my-2 gap-2 d-flex align-items-center '>
              <div onClick={()=>{
              setShowAddModal(true)
            }} className='btn btn-success d-flex align-items-center gap-2'>
                <AiOutlinePlusCircle className='text-light' style={{ cursor:'pointer',fontSize:'20px' }}/>
                <h4 className='text-light'>New Call</h4>
              </div>
            </div>
            {console.log(calls)}
            {/* <ReactTabulator
              data={calls}
              options={
                options
              }
              columns={callColumns}
              layout={"fitData"}
              /> */}
              <div style={gridStyle} className=  "ag-theme-quartz-dark">

              <AgGridReact
                  onFilterChanged={()=>{
                onFilterChanged()
              }}
              enableAdvancedFilter={true}
              grandTotalRow={"top"}
                  rowData={calls}
                  columnDefs={authUser?.user_type=='sells'?columnCallsDefs:columnsCallDefsSales}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowSelection="multiple"
                  rowDragManaged={true}
                  tooltipShowDelay={{tooltipShowDelay: 2}}
                  onSelectionChanged={onSelectionChanged}
                  rowMultiSelectWithClick={true}
                  />
                  </div>
          </>
        }
      </Modal>


      <Modal className='' style={{ width:'90vw' }} title="Client Calls" open={showAddModal} onOk={()=>{
        // setShowAddModal(false)
        handleAddCall();
      }} onCancel={()=>{
        setShowAddModal(false)
      }} centered>
        <form style={{width:'100%'}} action="">
          <div style={{width:'100%'}}>
            <label className='w-100' htmlFor="">FeedBack</label>
            <input value={newCall?.feed_back} onChange={(e)=>{
              setNewCall({...newCall,feed_back:e.target.value})
            }} className='w-100' type="text" placeholder='FeedBack' />
          </div>
          {/* <div style={{width:'100%'}}>
            <label className='w-100' htmlFor="">Call Duration</label>
            <input value={newCall?.duration} onChange={(e)=>{
              setNewCall({...newCall,duration:e.target.value})
            }} className='w-100' type="text" placeholder='Call Duration' />
          </div> */}
        </form>
      </Modal>


      <Modal className='' style={{ width:'90vw' }} title="Add Meeting" open={showAddMeeting} onOk={()=>{
        // setShowAddModal(false)
        handleAddMeeting();
      }} onCancel={()=>{
        setShowAddMeeting(false)
      }} centered>
        <form style={{width:'100%'}} action="">
        <>
            <label style={{marginTop:'10px'}} className='' htmlFor="">Date</label>
              <input
                style={{
                  width:'100%',
                  padding:'10px',
                  borderRadius:'10px',
                  outline:'none',
                }}
                type="date"
                value={meetingData?.date}
                onChange={(e)=>{
                  setMeetingData({...meetingData,date:e.target.value})
                }}
              />
              <label style={{marginTop:'10px'}} htmlFor="">Time</label>
              <input
                type="time"
                style={{
                  width:'100%',
                  padding:'10px',
                  borderRadius:'10px',
                  outline:'none',
                }}
                value={meetingData?.time}
                onChange={(e)=>{
                  setMeetingData({...meetingData,time:e.target.value})
                }}
              />
          </>
        </form>
        {
          meetingLoading&&<div className='my-3'>
            <p>Loading...</p>
          </div>
        }
      </Modal>



      <Modal
        className=" add_com_mod"
        style={{ width: "90vw", zIndex: 123324387874387434784387875412 }}
        title="Client Comments"
        open={openComment}
        onOk={() => {
          // setShowAddModal(false)
          handleAddComment();
        }}
        onCancel={() => {
          setOpenNewCommect(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
          </div>
        </form>
      </Modal>
      <Modal
        className="client_history_model"
        style={{ width: "90vw" }}
        title="Client Comments"
        open={ClientId}
        onOk={() => {
          setClientId(false);
        }}
        onCancel={() => {
          setClientId(false);
        }}
        centered
      >
        {callLoading ? (
          <div
            style={{
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              style={{ width: "40%" }}
              animationData={groovyWalkAnimation}
              loop={true}
            />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
        ) : (
          <>
            <div

              style={{ width: "fit-content", cursor: "pointer" }}
              className="my-2 gap-2 d-flex align-items-center w-100 d-flex align-items-center justify-content-end"
            >
              <div onClick={() => {
                setOpenNewCommect(true);
              }} className='btn btn-success d-flex align-items-center gap-2'>
                <AiOutlinePlusCircle
                  className="text-success text-light"
                  style={{ cursor: "pointer", fontSize: "20px" }}
                />
                <h4 className='text-light'>New Comment</h4>
              </div>
            </div>
            {/* <ReactTabulator
              data={commentsData}
              options={options}
              columns={commentColumns}
              layout={"fitData"}
            /> */}
            <div style={gridStyle}
              className={
                "ag-theme-quartz-dark"
              }>
                <AgGridReact
              // autoGroupColumnDef ={}
                  onFilterChanged={()=>{
                onFilterChanged()
              }}
              enableAdvancedFilter={true}
              grandTotalRow={"top"}
                  // ref={gridRef}
                  rowData={commentsData}
                  // sideBar={true}
                  columnDefs={columnComsDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowSelection="multiple"
                  // columnsi
                  rowDragManaged={true}
                  tooltipShowDelay={{tooltipShowDelay: 2}}
                  onSelectionChanged={onSelectionChanged}
                  rowMultiSelectWithClick={true}
                />
              </div>

          </>
        )}
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 1233223412 }}
        title="Client Comments"
        open={oldComment}
        onOk={() => {
          // setShowAddModal(false)
          handleUpdateComment();
        }}
        onCancel={() => {
          setOldComment(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={oldData?.comment}
              onChange={(e) => {
                setOldData({
                  ...oldData,
                  comment: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </form>
      </Modal>

      <Modal title="Assign Clients" open={showModal}onCancel={()=>{
        setShowModal(false)
      }} centered>
        <div className='new_status'>
          <p onClick={()=>{
            setNewStatus('unAssign')
          }} className={newStatus=='unAssign'?'active':''}>UnAssign</p>
          <p onClick={()=>{
            setNewStatus('re_assign')
          }} className={newStatus=='re_assign'?'active':''}>ReAssign</p>
        </div>
        <div className='my-2'>
          {
            newStatus=='unAssign'?
            <div className='text-center'>
              <Button style={{display:'block',margin:'10px auto'}} onClick={()=>{
                unAssignCleints();
              }} className='btn btn-danger'>
                {
                  unAssLoading?(
                    <Spin/>
                  ):(
                    "UnAssign"
                  )
                }
            </Button>
            </div>
            :
            <div>
              <label htmlFor="">Employees</label>
              <Select
                style={{width:'100%'}}
                // style={{

                // }}
                value={selectedemployee}
                onChange={(e)=>{
                  //console.log(e)
                  setselectedemployee(e);
                }}
                options={employeessdata.map((item,index)=>{
                    return {label:`${item.name}`, value:`${item.agent_id}`};
                  })
                }
              />
              <Button style={{display:'block',margin:'10px auto'}} onClick={()=>{
              reAssisgnFunc();
            }} className='btn btn-primary'>
              {
                assignloading?(
                  <Spin/>
                ):(
                  "Reassign"
                )
              }
          </Button>
            </div>
          }
        </div>
        </Modal>


          </div>
        )
      }
    </>
  )
}

export default Interested
