import { Button, Card, Col, Input, Modal, Select, Space, Spin, Table } from 'antd';
import axios from 'axios';
import Lottie from 'lottie-react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { AiOutlinePlusCircle, AiOutlineSearch } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import { toast } from 'react-toastify';
import "tabulator-tables/dist/css/tabulator.min.css";
import * as XLSX from 'xlsx';
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
import { AppContext } from '../../components/context/AppContextProvider';
import ReactDragListView from 'react-drag-listview';
import './pendingclients.css';
import { AgGridReact } from 'ag-grid-react';
const PendingCleints = () => {
  const [openComment, setOpenNewCommect] = useState(false);

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) ,
        Number(dateParts[0]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2024,
    maxValidYear: 3000,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };
  let dataTableRef = React.useRef(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [calls, setCalls] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [rowData, setRowData] = useState({});
  const authUser = localStorage.getItem("authUser");
  let uData = JSON.parse(authUser);
  const [selectedRows,setSelectedRows]=useState([]);

  const [selectAll, setSelectAll] = useState(false)
  const [filteredRowCount,setFilteredRowCount]=useState([])
  const gridRef = useRef();

  // console.log(uData,"uData")
  const [newCall, setNewCall] = useState({
    feed_back: '',
    duration: '',
  })
  const { user } = useContext(AppContext);
  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    // pagination:true, //enable pagination
    // paginationSize:10, //optional parameter to request a certain number of rows per page
    // paginationInitialPage:1,
    // paginationSizeSelector:[5,10,20, 25,30,40, 50],
    movableRows: true, movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  // console.log(user)
  const [showCalls, setShowCalls] = useState(false);
  const [callLoading, setCallLoading] = useState(false);
  let agentData = JSON.parse(localStorage.getItem("authUser"));
  const [unAssLoading, setUnAssLoading] = useState(false)
  const [newStatus, setNewStatus] = useState('unAssign');
  const [pageloading, setpageloading] = useState(true);
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([]);
  const [disConf, setDisConf] = useState(true)
  const [selectedemployee, setselectedemployee] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [employeessdata, setemployeessdata] = useState([]);
  const [assignloading, setassignloading] = useState(false)
  const [showHistoyModel, setShowHistoyModel] = useState(false);
  const [callBackData, setCallBackData] = useState({
    time: '',
    date: '',
  })
  const onFilterChanged = (params) => {
    const rowCount = params.api.getDisplayedRowCount();
    setFilteredRowCount(rowCount);
  };
  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log('Content copied to clipboard');
      toast.success('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  const nav = useNavigate()


  const handleAddCall=()=>{
    // setAddLoading(true)
    const data_send={
      ...newCall,
      client_id:rowData?.client_id,
      agent_id:agentData?.agent_id
    }
    console.log(data_send)
    // return ;
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/add_new_call.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        setNewCall({duration:'',feed_back:''})
        handleGetCalls(rowData?.client_id)
        setShowAddModal(false)
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else{
        toast.error("Something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setAddLoading(false)
    })
  }
  const [comment, setComment] = useState("");
  const [commentsData, setCommentsData] = useState([]);


  const handleGetComments = async (id) => {
    setCallLoading(true);
    const data_send = {
      client_id: id,
    };
    await axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_comments.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res.message);
        if (res.status == "success") {
          if (Array.isArray(res.message)) {
            setCommentsData(res.message);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setCallLoading(false);
      });
  };
  const handleAddComment = () => {
    // setAddLoading(true)
    console.log(ClientId)
    const data_send = {
      comment: comment,
      client_id: ClientId?.client_id,
      agent_id: uData?.agent_id,
    };
    console.log(data_send);
    // return ;
    axios
      .post(
        "https://camp-coding.tech/new_quad_wall/quad_crm/admin/insert_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setNewCall({ duration: "", feed_back: "" });
          handleGetComments(ClientId?.client_id);
          setOpenNewCommect(false);
          setComment('')
          setClientId(null)
          setShowAddModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const [ClientId, setClientId] = useState(null);
  const [oldComment, setOldComment] = useState(null);


  const phone1lead = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone = document.createElement('span')
    phone.innerText = rowData.phone;
    // div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy = document.createElement("img");

    imgcopy.src = rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0") ? rowData.phone : `${rowData.phone}`}`, '_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData, "copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div = document.createElement('div');
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    div.appendChild(imgs_div)
    div.style.display = 'flex';
    div.style.alignItems = 'center';
    div.style.justifyContent = 'space-between'
    if (rowData?.phone != null && rowData?.phone != '') {

      div.appendChild(imgs_div)
      div.appendChild(phone);
    }
    // console.log(rowData.phone)
    imgcopy.addEventListener('click', function () {
      // console.log(rowData)
      copyContent(rowData.phone)
    })
    return div;
  }

  const phone2lead = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone = document.createElement('span')
    phone.innerText = rowData.phone2;
    // div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy = document.createElement("img");

    imgcopy.src = rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0") ? rowData.phone : `${rowData.phone}`}`, '_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData, "copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div = document.createElement('div');
    imgs_div.appendChild(imgcopy)
    imgs_div.appendChild(img)
    // div.appendChild(imgs_div)
    if (rowData?.phone2 != null && rowData?.phone2 != '') {

      div.appendChild(imgs_div)
      div.appendChild(phone);
    }
    div.style.display = 'flex';
    div.style.alignItems = 'center';
    div.style.justifyContent = 'space-between'
    imgcopy.addEventListener('click', function () {
      console.log(rowData)
      copyContent(rowData.phone)
    })
    return div;
  }
  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) + 0; // Get the row position and add 1 to make it 1-based
  };

  const reassignForm = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const check_box = document.createElement('input');
    check_box.type = "checkbox"
    check_box.checked = rowData?.check
    check_box.style.width = '20px';
    check_box.style.height = '20px';
    check_box.style.cursor = 'pointer';
    check_box.addEventListener('click', function () {
      setData(data.map((item) => {
        return { ...item, check: item.id == rowData.id ? !item.check : item.check }
      }))
    });
    return check_box
  }
  useEffect(() => {
    console.log(data)
    let selected = data.filter(it => it.check)
    if (selected.length > 0) {
      setDisConf(false)
    }
    else {
      setDisConf(true)
    }
    // let n=0;
    // for(let i=0;i<data.length;i++){
    //   if(data[i]['check']){
    //     // setDisConf(false)
    //     n++;
    //   }
    //   // else {

    //   // }

    //   }
    //   if(n>1){
    //     setDisConf(false)
    //     // n=0;
    //   }
    //   else{
    //     setDisConf(true)
    //     // n=0;

    //   }
  }, [data])
  const unassForm = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const check_box = document.createElement('input');
    check_box.type = "checkbox"
    check_box.checked = rowData?.un_ass_check
    check_box.style.width = '20px';
    check_box.style.height = '20px';
    check_box.style.cursor = 'pointer';
    check_box.addEventListener('click', function () {
      setData(data.map((item) => {
        return { ...item, un_ass_check: item.id == rowData.id ? !item.un_ass_check : item.un_ass_check }
      }))
    });

    return check_box
  }

  const handleGetCalls = async (id) => {
    setCallLoading(true)
    const data_send = {
      client_id: id
    }
    await axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/new_client_calls.php", JSON.stringify(data_send))
      .then((res) => {
        console.log(res.message)
        if (res.status == 'success') {
          if (Array.isArray(res.message)) {
            setCalls(res.message);
          }
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        setCallLoading(false)
      })
  }

  const callField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement('div');
    const btn = document.createElement('button');
    btn.innerText = "Calls";
    btn.style.color = "white";
    btn.style.backgroundColor = "#0b5ed7"
    btn.style.border = 'none'
    btn.style.padding = '10px'
    btn.style.borderRadius = '4px'
    btn.addEventListener('click', function () {
      console.log(rowData);
      handleGetCalls(rowData?.client_id)
      setRowData(rowData?.client_id)
      setShowCalls(true)
    })
    div.appendChild(btn)

    return div
  }

  var headerMenu = function () {
    var menu = [];
    var columns = this.getColumns();

    for (let column of columns) {

      //create checkbox element using font awesome icons
      let icon = document.createElement("i");
      icon.classList.add("fas");
      icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

      //build label
      let label = document.createElement("span");
      let title = document.createElement("span");

      title.textContent = " " + column.getDefinition().title;

      label.appendChild(icon);
      label.appendChild(title);

      //create menu item
      menu.push({
        label: label,
        action: function (e) {
          //prevent menu closing
          e.stopPropagation();

          //toggle current column visibility
          column.toggle();

          //change menu item icon
          if (column.isVisible()) {
            icon.classList.remove("fa-square");
            icon.classList.add("fa-check-square");
          } else {
            icon.classList.remove("fa-check-square");
            icon.classList.add("fa-square");
          }
        }
      });
    }

    return menu;
  };

  const agentField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement('div');
    div.innerText = rowData?.agent?.name;
    return div;
  }

  const agentEmailField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement('div');
    div.innerText = rowData?.agent?.user_email;
    return div;
  }

  const agentEmailPhone = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement('div');
    div.innerText = rowData?.agent?.phone;
    return div;
  }

  const [historyLoading, setHistoryLoading] = useState(false)
  const [ClientHistory, setClientHistory] = useState([])
  const handleGetHistoy = (id) => {
    setHistoryLoading(true)
    const data_send = {
      client_id: id,
    }
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/get_client_history.php", JSON.stringify(data_send))
      .then((res) => {
        if (res.status == 'success') {
          if (Array.isArray(res.message)) {
            setClientHistory(res.message)
          }
        }
        console.log(res)

      })
      .catch(e => console.log(e))
      .finally(() => {
        setHistoryLoading(false)
      })
  }

  const HistoryAction = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const button = document.createElement("button");
    button.innerText = "History";
    button.style.cursor = "pointer";
    button?.classList?.add('btn-success')
    button.style.color = 'white'
    button.style.backgroundColor = '#0b5ed7'
    button.style.border = 'none'
    button.style.borderRadius = '4px'
    button.style.padding = '4px'
    // Add click event listener
    button.addEventListener("click", () => {
      setShowHistoyModel(true)
      console.log(rowData?.client_id)
      handleGetHistoy(rowData?.client_id)
    });

    return button;
  };
  const [addLoading, setAddLoading] = useState(false)





  const SalesField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement('div');
    let sales = [...rowData?.sales];
    for (let i = 0; i < sales?.length; i++) {
      console.log(sales)
      let p = document.createElement('p');
      p.innerText = sales[i].name;
      div.appendChild(p)
    }
    return div
  }

  const SalesEmailField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement('div');
    let sales = [...rowData?.sales];
    for (let i = 0; i < sales?.length; i++) {
      console.log(sales)
      let p = document.createElement('p');
      p.innerText = sales[i].user_email;
      div.appendChild(p)
    }
    return div
  }

  const SalesPhoneField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement('div');
    let sales = [...rowData?.sales];
    for (let i = 0; i < sales?.length; i++) {
      console.log(sales)
      let p = document.createElement('p');
      p.innerText = sales[i].phone;
      div.appendChild(p)
    }
    return div
  }
  const [columns, setColumns] = useState([]);

  // const columns = ;
  const historyColumns = [
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center', headerMenu }, // Row number column
    { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input", headerMenu },
    { title: 'duration', field: 'duration', sorter: 'string', headerFilter: "input", headerMenu },
    // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'Call Date', field: 'date', sorter: 'date', sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date", headerMenu },
    { title: 'Agent Name', field: 'agent', formatter: agentField, sorter: 'string', headerMenu },
    { title: 'Agent Email', field: 'agent', formatter: agentEmailField, sorter: 'string', headerMenu },
    { title: 'Agent Phone', field: 'agent', formatter: agentEmailPhone, sorter: 'string', headerMenu },
  ];


  const upColumns = () => {
    const iniColumns = [
      // { title: 'Number', field: 'number', sorter: 'number', headerFilter: "input" },
      { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center' }, // Row number column

      // { title: 'ID', field: 'client_id', sorter: 'number', headerFilter: "input" },
      { title: 'Name', field: 'name', sorter: 'string', headerFilter: "input" },
      { title: 'Email', field: 'email', sorter: 'string', headerFilter: "input" },
      { title: 'Status Label', field: 'status_label', sorter: 'string', headerFilter: "input" },
      { title: 'Phone', field: 'phone', formatter: phone1lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" },
      { title: 'Phone2', field: 'phone2', formatter: phone2lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" },
      { title: 'Address', field: 'address', sorter: 'string', headerFilter: "input" },
      { title: 'Added Date', field: 'added_at', sorter: 'date', sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" },
      { title: 'Sale Name', field: 'phone2', formatter: SalesField, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" },
      { title: 'Sale Email', field: 'phone2', formatter: SalesEmailField, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" },
      { title: 'Sale Phone', field: 'phone2', formatter: SalesPhoneField, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone" },

      { title: 'Check', field: 'client_id', formatter: reassignForm, width: 100 },
      // { title: 'History', field: 'name',formatter:HistoryAction, width: 100 },

      { title: 'Calls', field: 'phone', formatter: callField, headerFilterPlaceholder: "phone", headerMenu },

      // { title: 'ReAssign', field: 'client_id',formatter:reassignForm,width:100 },
      // { title: 'UnAssign', field: 'client_id',formatter:unassForm,width:100 },
    ]
    setColumns(iniColumns);
  }


  const getNotAnswer = () => {
    setpageloading(true)
    const data_send = {
      // status:"pending",
      // client_type:"all",
      status: "pending",
      client_type: "all",
      agent_type: uData?.user_type,
      agent_id: uData?.agent_id
    }
    axios.post('https://camp-coding.tech/new_quad_wall/quad_crm/admin/ope_mar_clients_status.php', data_send)
      .then((res) => {
        console.log(res, "this is response")
        if (Array.isArray(res.message)) {
          setData(res.message.map((item) => {
            return { ...item, image: require("../../assets/images/whats.png"), copyimg: require("../../assets/images/copy.png"), re_ass_check: false, un_ass_check: false, check: false }
          }))
          setOriginalData(res.message.map((item) => {
            return { ...item, image: require("../../assets/images/whats.png"), copyimg: require("../../assets/images/copy.png"), re_ass_check: false, un_ass_check: false, check: false }
          }))
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        setpageloading(false)
      })
  }


  const geteployees = () => {
    axios.get("https://camp-coding.tech/new_quad_wall/quad_crm/admin/select_ageint.php")
      .then((res) => {
        //console.log(res)
        //console.log(res);
        setemployeessdata(res.message);
        if (res.message.length > 0)
          setselectedemployee(res?.message[0]?.agent_id)
        //console.log(res?.message[0]?.name);
      })
  }
  // const handleSearch=(txt)=>{
  //   // if(txt==''){
  //   //   setData(originalData)
  //   // }
  //   // else {
  //   //   const pushed=[];
  //   //   for(let i=0;i<originalData.length;i++){
  //   //     if(originalData[i].name.includes(txt)||originalData[i].phone.includes(txt))
  //   //     pushed.push(originalData[i]);
  //   //   }
  //   //   setData(pushed)
  //   // }
  //   if(txt==''){
  //     setData(originalData)
  //   }
  //   else {
  //     if(true){
  //       // console.log('rere')
  //       let pushed=originalData.filter((it)=>{
  //         // console.log(it.name)
  //         if(it.name!=null){
  //           if(it.name.includes(txt)||it.phone.includes(txt)){
  //             return {...it}
  //           }
  //         }
  //         return null
  //       });
  //       console.log(pushed)
  //       setData(pushed)
  //       // setData(originalData.filter(it=>it.name.includes(txt)||it.phone.includes(txt)));
  //     }
  //     // else {
  //     //   setclients(originalColds.filter(it=>it.name.includes(txt)||it.phone.includes(txt)));
  //     // }
  //   }

  // }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<AiOutlineSearch />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <AiOutlineSearch
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const unAssignCleints = () => {
    console.log(data)
    setUnAssLoading(true)
    let selectedtxt = '';
    const selected = selectedRows
    for (let i = 0; i < selected.length; i++) {
      if (i == 0) {
        selectedtxt += selected[i].client_id;
      }
      else {
        selectedtxt += "**UAD**" + selected[i].client_id;
      }
    }
    if (selectedtxt == '') {
      toast.warn('Select From Unassign To Make Request')
      return;
    }
    const data_send = {
      client_id_data: selectedtxt,
    }
    console.log(data_send);
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/unassign_clients.php", JSON.stringify(data_send))
      .then((res) => {
        console.log(res)
        if (res.status == 'success') {
          toast.success(res.message)
          setShowModal(false)

          getNotAnswer()
          // setData(data.map((it)=>{
          //   return {...it}
          // }));
        }
        else if (res.status == 'error') {
          toast.error(res.message)
        }
        else {
          toast.error(res.message)
        }
      })
      .catch(e => console.log(e))
      .finally(() => {
        setUnAssLoading(false)
      })
  }

  const reAssisgnFunc = () => {
    let selected = [...data];
    let checddata = selectedRows;
    let selectedtxt = "";
    if (selectedemployee == "") {
      toast.warn("select at least one employee")
      return
    }
    for (let i = 0; i < checddata.length; i++) {
      if (i == 0) {
        selectedtxt += checddata[i].client_id;
      }
      else {
        selectedtxt += "**UAD**" + checddata[i].client_id;
      }
    }
    const data_send = {
      client_id_data: selectedtxt,
      agent_id: selectedemployee,
      operation_id:uData.agent_id
    }
    console.log(data_send);
    // return
    setassignloading(true);
    axios.post("https://camp-coding.tech/new_quad_wall/quad_crm/admin/reassign_agent_to_client.php", JSON.stringify(data_send))
      .then((res) => {
        console.log(res)
        if (res.status === "success") {
          let alldata = [...data];
          alldata.map((item) => {
            return { ...item, check: false };
          })
          //retnotchecked();
          setData(alldata)
          setShowModal(false)
          toast.success(res.message);
          getNotAnswer();
        }
        else if (res.status == "error") {
          toast.error(res.message);
        }
        else {
          toast.error("something is error try again")
        }
      }).finally(() => {
        setassignloading(false);
      })
  }

  const handleExport = () => {

    const pp = [];
    data.map((item, index) => {
      if (item.check === true) {
        console.log('dsd');
        let newobj = {
          'Cleint name': item.name || '',
          'Cleint phone': item.phone || '',
          'Cleint phone2': item.phone2 || '',
          'Cleint email': item.email || '',
          'Cleint status': item.status || '',
          'Cleint project_intrested_in': item.project_intrested_in || '',
          'Cleint time_to_call': item.time_to_call || '',
          'Cleint unitcode': item.unitcode || '',
          'Cleint client_type': item.client_type || '',
          'Cleint job': item.job || '',
          'Cleint address': item.address || '',
        };
        let sales = [...item?.sales];
        let divNames = document.createElement('div');
        let divEmails = document.createElement('div');
        let divPhones = document.createElement('div');
        for (let i = 0; i < sales?.length; i++) {
          console.log(sales)
          let p = document.createElement('p');
          p.innerText = sales[i].name;
          newobj[`agent Name ${i + 1}`] = sales[i].name
          newobj[`agent Email ${i + 1}`] = sales[i].user_email
          newobj[`agent Phone ${i + 1}`] = sales[i].phone
          divNames.appendChild(p)
        }

        // newobj['agent Name']=divNames;
        // newobj['agent Email']=divEmails;
        // newobj['agent Phone']=divPhones;
        // let divEmails=document.createElement('div');
        // for(let i=0;i<sales?.length;i++){
        //   console.log(sales)
        //   let p=document.createElement('p');
        //   p.innerText=sales[i].user_email;
        //   divEmails.appendChild(p)
        // }
        // newobj['agent Email']=divEmails;
        // let divPhones=document.createElement('div');
        // for(let i=0;i<sales?.length;i++){
        //   console.log(sales)
        //   let p=document.createElement('p');
        //   p.innerText=sales[i].user_email;
        //   divPhones.appendChild(p)
        // }
        // newobj['agent Phone']=divPhones;
        pp.push(newobj);
      }
    });
    if (pp.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    // XLSX.writeFile(wb, 'MyExcel.XLSX');
  };

  const [hiddenColumns, setHiddenColumns] = useState([])
  useEffect(() => {
    try {
      if (localStorage) {
        const allHiddenColumns = JSON.parse(localStorage?.getItem("pendingHiddenColumns"));
        if (allHiddenColumns && allHiddenColumns?.length)
          setHiddenColumns(allHiddenColumns);
        else setHiddenColumns([]);
      }
    } catch (e) {
      setHiddenColumns([]);
      console.log(e)
    }
  }, [localStorage])
  const handleColumnVisibility = (selectedColumns) => {
    setHiddenColumns(selectedColumns);
    localStorage.setItem("pendingHiddenColumns", JSON.stringify(selectedColumns))
  };
  const [visible, setVisible] = useState(false);

  const renderUsers = [
    {
      title: 'Select',
      dataIndex: 'name',
      key: 'sele',
      width: '30%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <input onClick={() => {
              setData(data.map((item) => {
                return { ...item, check: item.client_id == record.client_id ? !record.check : item.check }
              }))
            }} checked={record.check} type="checkbox" style={{ width: '15px', height: '15px' }} />
          </div>
        </Space>
      ),
      hidden: hiddenColumns.includes('sele'),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
      hidden: hiddenColumns.includes('name'),
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
      hidden: hiddenColumns.includes('status'),
    },
    {
      title: 'Time To Call',
      dataIndex: 'time_to_call',
      key: 'time_to_call',
      width: '20%',
      ...getColumnSearchProps('time_to_call'),
      hidden: hiddenColumns.includes('time_to_call'),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div>
            {
              record.phone != null && record.phone != "" &&
              <div>
                <img style={{ width: '30px' }} onClick={() => {
                  window.open(`whatsapp://send?text=!&phone=+2${record.phone.startsWith("0") ? record.phone : `${record.phone}`}`, '_blank')
                }} src={require("../../assets/images/whats.png")} alt="" />
                <img style={{ width: '30px' }} onClick={() => {
                  copyContent(record.phone)
                }} src={require("../../assets/images/copy.png")} alt="" />
                <p>{record.phone}</p>
              </div>
            }
          </div>
        </Space>
      ),
      hidden: hiddenColumns.includes('phone'),
    },
    {
      title: 'Phone2',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      ...getColumnSearchProps('phone2'),
      render: (_, record) => (
        <Space>
          <div>
            {
              record.phone2 != null &&
              <div>
                <img style={{ width: '30px' }} onClick={() => {
                  window.open(`whatsapp://send?text=!&phone=+2${record.phone2.startsWith("0") ? record.phone2 : `${record.phone2}`}`, '_blank')
                }} src={require("../../assets/images/whats.png")} alt="" />
                <img style={{ width: '30px' }} onClick={() => {
                  copyContent(record.phone2)
                }} src={require("../../assets/images/copy.png")} alt="" />
                <p>{record.phone2}</p>
              </div>
            }
          </div>
        </Space>
      ),
      hidden: hiddenColumns.includes('phone2'),
    },
    {
      title: 'Sale Name',
      dataIndex: 'phone2',
      key: 'sale_2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales && record?.sales.map((item) => {
                return (
                  <p key={item.name}>{item.name}</p>
                )
              })
            }
          </div>
        </Space>
      ),
      hidden: hiddenColumns.includes('phone2'),
    },
    {
      title: 'Sale Email',
      dataIndex: 'phone2',
      key: 'sale_4',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales && record?.sales.map((item) => {
                return (
                  <p key={item.user_email}>{item.user_email}</p>
                )
              })
            }
          </div>
        </Space>
      ),
      hidden: hiddenColumns.includes('phone2'),
    },
    {
      title: 'Sale Phone',
      dataIndex: 'phone2',
      key: 'sale_5',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales && record?.sales.map((item) => {
                return (
                  <p key={item.phone}>{item.phone}</p>
                )
              })
            }
          </div>
        </Space>
      ),
      hidden: hiddenColumns.includes('phone2'),
    },
    {
      title: 'Calls',
      dataIndex: 'phone2',
      key: 'sale_6',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <button onClick={() => {
              handleGetCalls(record?.client_id);
              setRowData(record?.client_id);
              setShowCalls(true);
            }} className="btn btn-primary">
              Calls
            </button>
          </div>
        </Space>
      ),
      hidden: hiddenColumns.includes('phone2'),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ...getColumnSearchProps('address'),
      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ['descend', 'ascend'],
      hidden: hiddenColumns.includes('address'),
    },

  ].filter(column => !column.hidden);

  const allColumns = [
    { key: 'name', title: 'Name' },
    { key: 'sele', title: 'Select' },
    { key: 'status', title: 'Status' },
    { key: 'time_to_call', title: 'Time To Call' },
    { key: 'phone', title: 'Phone' },
    { key: 'phone2', title: 'Phone2' },
    { key: 'sale_name', title: 'Sale Name' },
    { key: 'sale_email', title: 'Sale Email' },
    { key: 'sale_phone', title: 'Sale Phone' },
    { key: 'calls', title: 'Calls' },
    { key: 'address', title: 'Address' }
  ];
const [columnsSet, setColumnsSet] = useState(renderUsers)
  const onDragEnd = useCallback((fromIndex, toIndex) => {
    const newColumns = [...columnsSet];
    const item = newColumns.splice(fromIndex, 1)[0];
    newColumns.splice(toIndex, 0, item);
    setColumnsSet(newColumns);
  }, [columnsSet, hiddenColumns]);

  const dragProps = {
    onDragEnd,
    nodeSelector: "th",
  };


  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      editable: true,
      filter: true,
      floatingFilter: true,
      onCellValueChanged: (e) => console.log(e),
      // flex: 10,
      width:'fit-content'
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => {
        // setRowData(data);
      });
  }, []);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows())
    if(event.api.getSelectedRows().lengt>0){
      setDisConf(true);
    }
    else {
      setDisConf(false);
    }
  };
  const isRowSelectable = (node) => {
    return true; // or node.data.<column>
  };
  const onExportClick = () => {
    // gridApi.api.exportDataAsCsv();
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData)
    const params = {
      fileName: 'selected_rows.csv',
      columnSeparator: ',',
      skipHeader: false,
      columnKeys: ['make', 'model', 'price']
    };
    const pp = [];
    selectedData.map((item, index) => {
      if (true) {
        console.log('dsd');
        let newobj = {
          'Client name': item.name || '',
          'Client phone': item.phone || '',
          'Client phone2': item.phone2 || '',
          'Client project_intrested_in': item.project_intrested_in || '',
          'Client time_to_call': item.time_to_call || '',
          'Client unitcode': item.unitcode || '',
          'Client client_type': item.client_type || '',
          'Client status': item.status || '',
          'Client status label': item.status_label || '',
          'Client job': item.job || '',
          'Client address': item.address || '',
          'agent name': item?.sales[0].name || '',
          'agent Email': item?.sales[0].user_email || '',
          'agent Phone': item?.sales[0].phone || '',
        };
        pp.push(newobj);
      }
    });
    if (selectedData.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    // setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // gridRef.current.api.exportDataAsCsv(params, {
    //   rowData: selectedData
    // });
  };


  useEffect(() => {
    setData(data.map((item) => {
      return { ...item, check: selectAll ? true : false }
    }))
    upColumns()
  }, [selectAll])

  useEffect(() => {
    getNotAnswer()
    geteployees()
    upColumns()
  }, [])

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'Name',
      field: "name",
      aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params?.data&&params?.data?.phone!=null&&params?.data.phone!=""&&
          <div>
                <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params?.data&&params.data.phone)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone",
      field: "phone",
      aggFunc:"sum",

      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params.data&&params.data.phone!=null&&params.data.phone!=""&&
          <div>
            <p>{params.data.phone}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "",
      field: "phone2",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {console.log(params.data)}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
                          <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params.data.phone2)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone2",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
            <p>{params.data.phone2}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "project intrested in",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.project_intrested_in}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },
    {
      headerName: "Comments",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetComments(params?.data?.client_id);
                setRowData(params.data);
                console.log(params?.data)
                setClientId(params?.data);
              }}
            >Comments</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Calls",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetCalls(params.data?.client_id)
                setRowData(params.data)
                setShowCalls(true)
              }}
            >Calls</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Profile",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                window.location.href = "/UserProfile/" + params?.data?.client_id;

              }}
            >Profile</button>
      ),
      sortable: false,
    },
    {
      headerName: "History",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                setShowHistoyModel(true)
                console.log(params.data,"paramsparams")
                handleGetHistoy(params?.data?.client_id)
              }}
            >History</button>
      ),
      sortable: false,
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
    },
    {
      headerName: "sale name",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].name}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale email",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].user_email}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale phone",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.sales&&params?.data?.sales[0]&&params?.data?.sales[0].phone}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "address",
      field: "address",
      sortable: true,
    },
    {
      headerName: "added at",
      field: "added_at",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "last_action_date",
      field: "last_action_date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "last action date",
      field: "added_at",
      sortable: true,
      hide: true,
    },

  ]);

  return (
    <>
      {
        pageloading ? (
          [1, 2, 3].map(x => (
            <Col xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ) : (
          <div style={{
            marginTop: '30px',
            overflow: 'auto',
            minWidth: '100%'
          }} className='py-5 container'>

            {/* <div className='my-2 p-2'>
        <input placeholder='Search' className='form-control' type="text" onChange={(e)=>{
          e.preventDefault();
          handleSearch(e.target.value)
        }} />
      </div> */}
            {/* <div className="selecter">
              <label htmlFor="">Remove Labels: </label>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select columns to hide"
                onChange={handleColumnVisibility}
                value={hiddenColumns}
              >
                {allColumns.map(column => (
                  <option key={column.key} value={column.key}>
                    {column.title}
                  </option>
                ))}
              </Select>
            </div> */}
            <div className="selecter">
              {/* <label className='btn btn-primary' onClick={() => setVisible(true)} htmlFor="">Change Order: </label> */}

            </div>
            <div className='d-flex align-items-center gap-1 justify-content-end mb-2'>
              <div className='my-3'>
                <button
                  disabled={disConf}
                  className='btn btn-success'
                  onClick={() => {
                    let selected = selectedRows;
                    if (selectedRows.length == 0) {
                      toast.warn('Select Cleints First');
                      return
                    }
                    setShowModal(true)
                  }}
                >Confirm</button>
              </div>
            </div>

            <div className='d-flex align-items-center justify-content-between'>
              {/* <div className='d-flex align-items-center gap-2 my-2'>
                <h4>Select All</h4>
                <input onChange={(e) => {
                  setSelectAll(!selectAll)
                }} checked={selectAll} style={{
                  width: '15px',
                  height: '15px',

                }} type="checkbox" />
              </div> */}
              <div className="my-3">
                <div className='d-flex gap-2'>
                  <button
                    onClick={() => {
                      // handleExport();
                      onExportClick()
                    }}
                    className="btn btn-primary text-light"
                  >
                    Export Excel
                  </button>
                  {/* <button className='btn btn-success' onClick={() => {
                    // let table=document.getElementsByClassName('tabulator')
                    // console.log(table)
                    // generatePDF(dataTableRef, {filename: 'page.pdf'})
                    // console.log(dataTableRef)
                    var table = new Tabulator("#example-table", {
                      height:"311px",
                      columns:[
                          ...columns
                      ],
                  });
                  table.download("pdf", "data.pdf", {
                    orientation:"portrait", //set page orientation to portrait
                    title:"Example Report", //add title to report
                });

                  }}>Download PDF</button> */}
                </div>
              </div>
            </div>
            <div style={{ height:'100vh' }} className="ag-theme-quartz-dark">
              <AgGridReact

              // autoGroupColumnDef ={}
              onFilterChanged={()=>{
                onFilterChanged()
              }}
              enableAdvancedFilter={true}
              grandTotalRow={"top"}
                  ref={gridRef}
                  rowData={data}
                  // sideBar={true}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  rowSelection="multiple"
                  // columnsi
                  rowDragManaged={true}
                  tooltipShowDelay={{tooltipShowDelay: 2}}
                  onSelectionChanged={onSelectionChanged}
                  rowMultiSelectWithClick={true}
                  // ref={gridRef}
                  isRowSelectable={isRowSelectable}
            // pagination={true}
            // quickFilterText={quickSearchValue}


                />
            </div>
            {/* <ReactDragListView.DragColumn {...dragProps}>
              <Table style={{ marginTop: '10px' }} dataSource={data} columns={columnsSet} />
            </ReactDragListView.DragColumn> */}

            {/* <ReactTabulator
          data={data}
          ref={r => (dataTableRef.current = r)}
          options={
            options
          }
          // sortOrderReverse={true}
          columns={columns}
            layout={"fitData"}
        /> */}
            {/* <div className='my-2 text-center'>
          <Button style={{display:'block',margin:'10px auto'}} onClick={()=>{
                unAssignCleints();
              }} className='btn btn-danger'>
                {
                  unAssLoading?(
                    <Spin/>
                  ):(
                    "UnAssign"
                  )
                }
            </Button>
        </div> */}

            <Modal title="Assign Clients" open={showModal} onCancel={() => {
              setShowModal(false)
            }} centered>
              <div className='new_status'>
                <p onClick={() => {
                  setNewStatus('unAssign')
                }} className={newStatus == 'unAssign' ? 'active' : ''}>UnAssign</p>
                <p onClick={() => {
                  setNewStatus('re_assign')
                }} className={newStatus == 're_assign' ? 'active' : ''}>ReAssign</p>
              </div>
              <div className='my-2'>
                {
                  newStatus == 'unAssign' ?
                    <div className='text-center'>
                      <Button style={{ display: 'block', margin: '10px auto' }} onClick={() => {
                        unAssignCleints();
                      }} className='btn btn-danger'>
                        {
                          unAssLoading ? (
                            <Spin />
                          ) : (
                            "UnAssign"
                          )
                        }
                      </Button>
                    </div>
                    :
                    <div>
                      <label htmlFor="">Employees</label>
                      <Select
                        style={{ width: '100%' }}
                        // style={{

                        // }}
                        value={selectedemployee}
                        onChange={(e) => {
                          //console.log(e)
                          setselectedemployee(e);
                        }}
                        options={employeessdata.map((item, index) => {
                          return { label: `${item.name}`, value: `${item.agent_id}` };
                        })
                        }
                      />
                      <Button style={{ display: 'block', margin: '10px auto' }} onClick={() => {
                        reAssisgnFunc();
                      }} className='btn btn-primary'>
                        {
                          assignloading ? (
                            <Spin />
                          ) : (
                            "Reassign"
                          )
                        }
                      </Button>
                    </div>
                }
              </div>
            </Modal>


            <Modal className='client_history_model' style={{ width: '90vw' }} title="Client History" open={showHistoyModel} onOk={() => {
              setShowHistoyModel(false)
            }} onCancel={() => {
              setShowHistoyModel(false)
            }} centered>
              {
                historyLoading ?
                  <div style={{
                    minHeight: '30vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Lottie style={{ width: '40%' }} animationData={groovyWalkAnimation} loop={true} />
                    {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
                  </div>
                  :
                  <>
                    <ReactTabulator
                      data={ClientHistory}
                      options={
                        options
                      }
                      columns={historyColumns}
                      layout={"fitData"}
                    />
                  </>
              }
            </Modal>



            <Modal className='client_history_model' style={{ width: '90vw' }} title="Client Calls" open={showCalls} onOk={() => {
              setShowCalls(false)
            }} onCancel={() => {
              setShowCalls(false)
            }} centered>
              {
                callLoading ?
                  <div style={{
                    minHeight: '30vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <Lottie style={{ width: '40%' }} animationData={groovyWalkAnimation} loop={true} />
                    {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
                  </div>
                  :
                  <>
                    <div onClick={() => {
                      setShowAddModal(true)
                    }} style={{ width: 'fit-content', cursor: 'pointer' }} className='my-2 gap-2 d-flex align-items-center '>
                      <AiOutlinePlusCircle className='text-success' style={{ cursor: 'pointer', fontSize: '20px' }} />
                      <h4>New Call</h4>
                    </div>
                    {console.log(calls)}
                    <ReactTabulator
                      data={calls}
                      options={
                        options
                      }
                      columns={historyColumns}
                      layout={"fitData"}
                    />
                  </>
              }
            </Modal>


            <Modal className='' style={{ width: '90vw' }} title="Client Calls" open={showAddModal} onOk={() => {
              // setShowAddModal(false)
              handleAddCall();
            }} onCancel={() => {
              setShowAddModal(false)
            }} centered>
              <form style={{ width: '100%' }} action="">
                <div style={{ width: '100%' }}>
                  <label className='w-100' htmlFor="">FeedBack</label>
                  <input value={newCall?.feed_back} onChange={(e) => {
                    setNewCall({ ...newCall, feed_back: e.target.value })
                  }} className='w-100' type="text" placeholder='FeedBack' />
                </div>
                <div style={{ width: '100%' }}>
                  <label className='w-100' htmlFor="">Call Duration</label>
                  <input value={newCall?.duration} onChange={(e) => {
                    setNewCall({ ...newCall, duration: e.target.value })
                  }} className='w-100' type="text" placeholder='Call Duration' />
                </div>
              </form>
            </Modal>



          </div>
        )
      }
    </>
  )
}

export default PendingCleints
